import { namedOperations, useDeleteYarnMutation, YarnDetailsFragment } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useYarnsSearchEngine from 'customHooks/searchEngine/useYarnsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import DeleteYarnModal from '../../DeleteYarnModal/DeleteYarnModal';


const YarnsListResultsView = () => {
	const {
		currentFilters,
		updateUrl,
		limit,
		loading,
		data
	} = useYarnsSearchEngine();
	const { notifyError, notifyInfo } = useNotifications();
	const [deleteYarn, { loading: deletingYarn }] = useDeleteYarnMutation({
		refetchQueries: [namedOperations.Query.getYarns],
		onCompleted: () => {
			notifyInfo(translate({ key: 'Yarn deleted' }));
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	return (
		<div className={'w-100'}>
			{(loading || deletingYarn) && <LoadingOverlay/>}
			<ListLayout
				items={data?.yarns || []}
				component={(item: YarnDetailsFragment) => <ListGridItem
					key={item._id}
					item={item}
					modalTitle={translate({ key: 'Delete yarn' })}
					navigateLink={`/yarn/${item._id}`}
					onModalConfirm={() => deleteYarn({ variables: { yarnId: item._id } })}
					modalContent={<DeleteYarnModal yarn={item}/>}
				/>}
				currentPage={currentFilters.page || 1}
				total={data?.count || 0}
				limit={limit}
				onPageChange={page => {
					updateUrl('page', page.toString());
				}}
			/>
		</div>
	);
};

export default YarnsListResultsView;
