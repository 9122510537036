import { useState } from 'react';
import {
	useGetInspirationsListLazyQuery,
	useGetPatternsLazyQuery,
	useGetStashListLazyQuery
} from '__generated__/graphql';
import AsyncAutoCompleteInput, { AsyncAutoCompleteInputOptionProps } from 'components/Inputs/AsyncAutoCompleteInput';
import DateInput from 'components/Inputs/DateInput';
import MultiSelectInput from 'components/Inputs/MultiSelect';
import SelectInput from 'components/Inputs/Select';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { useProjectFormContext } from 'customHooks/formContexts/useProjectFormContext';
import { translate } from 'services/i18n';
import { defaultPaginationParams } from '../../../../constants';


const ProjectFormContentView = () => {
	const FormContext = useProjectFormContext();
	const { state: project, handleChange } = FormContext;

	const { tags, projectStatuses, recipients, updateUserFilters } = useUserFilters();
	const [patterns, setPatterns] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.patterns || []).map(pattern => ({
		value: pattern._id,
		label: pattern.name,
		imageUrl: pattern.imageUrl,
		resourceType: pattern.patternType
	})));
	const [stash, setStash] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.stash || []).map(item => ({
		value: item.resourceId,
		label: item.name,
		imageUrl: item.imageUrl,
		resourceType: item.itemType
	})));
	const [inspirations, setInspirations] = useState<AsyncAutoCompleteInputOptionProps[]>((project?.inspirations || []).map(inspiration => ({
		value: inspiration._id,
		label: inspiration.name,
		imageUrl: inspiration.imageUrl,
		resourceType: null
	})));
	const [getPatterns] = useGetPatternsLazyQuery();
	const [getStash] = useGetStashListLazyQuery();
	const [getInspirations] = useGetInspirationsListLazyQuery();

	return (
		<GridContainer>
			<GridItem md={8}>
				<TextInput
					onChange={name => handleChange('name', name)}
					value={project.name}
					id={'project-name'}
					label={translate({ key: 'Name' })}/>
			</GridItem>
			<GridItem md={4}>
				<SelectInput
					data={projectStatuses}
					value={project.status ? {
						value: project.status,
						label: translate({ key: project.status })
					} : null}
					onChange={status => handleChange('status', status)}
					label={translate({ key: 'Status' })}/>
			</GridItem>
			<GridItem md={12}>
				<AsyncAutoCompleteInput
					label={translate({ key: 'Pattern(s)' })}
					id="project-patterns"
					value={patterns}
					fetchOptions={async (search: string) => {
						const { data } = await getPatterns({
							variables: {
								...defaultPaginationParams,
								name: search,
							}
						});
						return (data?.patterns?.patterns || []).map((pattern) => ({
							imageUrl: pattern.imageUrl,
							label: pattern.name,
							resourceType: pattern.patternType,
							value: pattern._id
						}));
					}}
					onChange={options => {
						setPatterns(options);
						handleChange('patternIds', options.map(({ value }) => value));
						handleChange('patterns', options.map(({ imageUrl, label }) => ({ imageUrl, name: label })));
					}}
				/>
			</GridItem>
			<GridItem md={12}>
				<AsyncAutoCompleteInput
					label={translate({ key: 'Materials' })}
					id="project-materials"
					value={stash}
					fetchOptions={async (search: string) => {
						const { data } = await getStash({
							variables: {
								...defaultPaginationParams,
								name: search
							}
						});
						return (data?.stash?.items || []).map((item) => ({
							imageUrl: item.imageUrl,
							label: item.name,
							resourceType: item.itemType,
							value: item._id
						}));
					}}
					onChange={options => {
						setStash(options);
						handleChange('stashIds', options.map(({ value }) => value));
					}}
				/> </GridItem>
			<GridItem md={12}>
				<AsyncAutoCompleteInput
					label={translate({ key: 'Inspiration(s)' })}
					id="project-inspirations"
					value={inspirations}
					fetchOptions={async (search: string) => {
						const { data } = await getInspirations({
							variables: {
								...defaultPaginationParams,
								name: search
							}
						});
						return (data?.inspirations?.inspirations || []).map((inspiration) => ({
							imageUrl: inspiration.imageUrl,
							label: inspiration.name,
							resourceType: null,
							value: inspiration._id
						}));
					}}
					onChange={options => {
						setInspirations(options);
						handleChange('inspirationIds', options.map(({ value }) => value));
					}}
				/> </GridItem>
			<GridItem md={12}>
				<MultiSelectInput
					label={translate({ key: 'Tags' })}
					data={tags.map(value => ({ value, label: value }))}
					value={project.tags.map(value => ({ value, label: value }))}
					onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
					onChange={(tags) => handleChange('tags', tags)}/>
			</GridItem>
			<GridItem md={6}>
				<SelectInput
					label={translate({ key: 'Recipients' })}
					data={recipients.map(value => ({ value, label: value }))}
					creatable
					value={project.recipient ? { value: project.recipient, label: project.recipient } : null}
					onCreate={(recipient: string) => updateUserFilters({ recipients: [recipient] })}
					onChange={(recipient) => handleChange('recipient', recipient)}/>
			</GridItem>
			<GridItem md={3}>
				<DateInput
					label={translate({ key: 'Started at' })}
					value={project.startedAt}
					onChange={startedAt => handleChange('startedAt', startedAt)}
				/>
			</GridItem>
			<GridItem md={3}>
				<DateInput
					label={translate({ key: 'Finished at' })}
					value={project.finishedAt}
					onChange={finishedAt => handleChange('finishedAt', finishedAt)}/>
			</GridItem>
			<GridItem md={12}>
				<TextEditor
					title={translate({ key: 'Notes' })}
					handleChange={notes => handleChange('notes', notes)}
					content={project.notes}
				/>
			</GridItem>
		</GridContainer>
	);
};

export default ProjectFormContentView;
