import {
	InspirationType,
	namedOperations,
	useDeleteInspirationMutation,
	useGetInspirationQuery
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import RemoteImage from 'components/base/RemoteImage';
import DetailsLayout from 'components/Layouts/DetailsLayout/DetailsLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import InspirationDetailsActiveProjectsView
	from './InspirationDetailsActiveProjectsView/InspirationDetailsActiveProjectsView';
import InspirationDetailsContentView from './InspirationDetailsContentView/InspirationDetailsContentView';
import RemoteVideo from '../../../components/Inputs/RemoteVideo';
import DeleteInspirationModal from '../DeleteInspirationModal/DeleteInspirationModal';

const InspirationDetailsView = () => {
	const isDemo = useIsDemo();
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const { loading, data } = useGetInspirationQuery({
		variables: {
			inspirationId: id,
			isDemo
		}
	});

	const [deleteInspiration] = useDeleteInspirationMutation({
		variables: { inspirationId: id },
		refetchQueries: [namedOperations.Query.getInspirationsList],
		onCompleted: () => {
			navigate('/inspirations');
		},
		onError: err => {
			notifyError(err.message);
		}
	});
	return (
		<FlexContainer column>
			{(loading) && <LoadingOverlay/>}
			{data?.inspiration && <DetailsLayout
				onDeleteConfirm={() => deleteInspiration()}
				deleteModalTitle={translate({ key: 'Inspiration deletion' })}
				deleteModalContent={<DeleteInspirationModal inspiration={data.inspiration}/>}
				editLink={`/inspirations/${data.inspiration._id}/edit`}
				itemName={data?.inspiration?.name || ''}
				imgContent={data.inspiration?.type === InspirationType.Image ?
					<RemoteImage src={data?.inspiration.imageUrl} alt={data.inspiration.name}/> :
					<RemoteVideo videoUrl={data?.inspiration.videoUrl}/>}
				mainContent={<InspirationDetailsContentView inspiration={data.inspiration}/>}
				notes={data.inspiration.notes}
				relatedEntitiesTitle={translate({
					key: 'Related projects',
					data: { count: data.inspiration.currentProjectsIds.length }
				})}
				relatedEntitiesContent={<InspirationDetailsActiveProjectsView inspiration={data.inspiration}/>}
			/>}
		</FlexContainer>
	);
};

export default InspirationDetailsView;
