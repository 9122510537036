import { useState } from 'react';
import { Typography } from '@mui/material';
import {
	namedOperations,
	PatternDetailsFragment,
	useSaveProjectMutation,
	YarnDetailsFragment
} from '__generated__/graphql';
import ActiveFiltersBox from 'components/base/ActiveFiltersBox';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import CheckboxInput from 'components/Inputs/Checkbox';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useYarnsSearchEngine from 'customHooks/searchEngine/useYarnsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import CreateProjectModalContentLayout
	from '../../../../components/Layouts/CreateProjectModalContentLayout/CreateProjectModalContentLayout';

const PatternDetailsYarnsView = ({ pattern }: { pattern: PatternDetailsFragment }) => {
	const navigate = useNavigate();
	const { notifyError } = useNotifications();
	const [page, setPage] = useState(1);
	const [filterOnQuantity, setFilterOnQuantity] = useState(!!pattern.fabricRequirement);
	const [filterOnYarnWeight, setFilterOnYarnWeight] = useState(!!pattern.recommendedYarnWeight.length);
	const [filterOnNeedlesSize, setFilterOnNeedlesSize] = useState(!!pattern.needlesSize);
	const { data, limit } = useYarnsSearchEngine({
		limit: 6,
		overrideFilters: {
			weightCategories: filterOnYarnWeight ? pattern.recommendedYarnWeight : null,
			page,
			needlesSize: filterOnNeedlesSize ? pattern.needlesSize : null,
			//TODO - adjust
			quantity: filterOnQuantity ? pattern.fabricRequirement : null,
		}
	});
	const [saveProject, { loading: savingProject }] = useSaveProjectMutation({
		onCompleted: ({ saveProject }) => {
			navigate(`/projects/${saveProject._id}`);
		},
		refetchQueries: [namedOperations.Query.getProjectsList],
		onError: error => notifyError(error.message)
	});

	return <FlexContainer column className="w-100">
		{savingProject && <LoadingOverlay/>}
		<Divider label={
			<Typography variant="subtitle1">
				{translate({ key: 'Yarns you can use for that pattern', data: { count: data?.count || 0 } })}
			</Typography>
		}/>
		{!!data?.yarns.length && (<>
			<ActiveFiltersBox>
				<CheckboxInput
					checked={filterOnYarnWeight}
					disabled={!pattern.recommendedYarnWeight.length}
					onChange={(checked) => setFilterOnYarnWeight(checked)}
					label={translate({ key: 'Yarn weight' })}
					id="pattern-yarn-weight"/>
				<CheckboxInput
					checked={filterOnQuantity}
					disabled={!pattern.fabricRequirement}
					onChange={(checked) => setFilterOnQuantity(checked)}
					label={translate({ key: 'Quantity' })}
					id="pattern-yarn-quantity"/>
				<CheckboxInput
					checked={filterOnNeedlesSize}
					disabled={!pattern.needlesSize}
					onChange={(checked) => setFilterOnNeedlesSize(checked)}
					label={translate({ key: 'Needles size' })}
					id="pattern-yarn-needles-size"/>
			</ActiveFiltersBox>
			<ListLayout
				className="mt-1"
				component={(item: YarnDetailsFragment) => <ListGridItem
					item={{
						name: item.name,
						imageUrl: item.imageUrl
					}}
					deletable={false}
					projectCreatable
					initialProjectName={`${pattern.name} / ${item.name}`}
					navigateLink={`/yarn/${item._id}`}
					modalTitle={'Project creation'}
					modalContent={<CreateProjectModalContentLayout
						headerText={'Create a project with these pattern and yarn'}
						stashImage={{ imageUrl: item.imageUrl, name: item.name }}
						patternImage={{ imageUrl: pattern.imageUrl, name: pattern.name }}
					/>}
					onModalConfirm={(name) => saveProject({
						variables: {
							input: {
								patternIds: [pattern._id],
								stashIds: [item.stashId],
								name
							}
						}
					})}
				/>
				}
				items={data.yarns}
				currentPage={page}
				total={data.count}
				limit={limit}
				onPageChange={page => {
					setPage(page);
				}}
			/>
		</>)}
		{!data?.yarns.length && `${translate({
			key: 'We found no yarns in your collection for that pattern'
		})
		}.`}
	</FlexContainer>;
};

export default PatternDetailsYarnsView;
