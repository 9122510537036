import { faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { translate } from 'services/i18n';
import RemoteVideo from './RemoteVideo';
import TextInput from './TextInput';
import { FlexContainer } from '../base/FlexContainer';
import { StyledPlaceholder } from '../base/RemoteImage';


interface VideoInputProps {
	videoUrl: string,
	onUrlChange: (url: string) => void
}

const VideoInput = ({ videoUrl, onUrlChange }: VideoInputProps) => {
	console.log('videoUrl', videoUrl);
	return (
		<FlexContainer column>
			<TextInput
				className={'mb-1'}
				value={videoUrl || ''}
				onChange={(url) => onUrlChange(url)}
				id="remote-img-url"
				label={`${translate({ key: 'Url' })} *`}
				placeholder={translate({ key: 'Video url' })}
			/>
			{videoUrl ? <RemoteVideo videoUrl={videoUrl}/> :
				<StyledPlaceholder>
					<FontAwesomeIcon icon={faVideo}/>
				</StyledPlaceholder>
			}
		</FlexContainer>
	);
};

export default VideoInput;
