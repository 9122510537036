import { useState } from 'react';
import { Typography } from '@mui/material';
import {
	FabricDetailsFragment,
	namedOperations,
	PatternDetailsFragment,
	PatternType,
	useSaveProjectMutation
} from '__generated__/graphql';
import ActiveFiltersBox from 'components/base/ActiveFiltersBox';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import CheckboxInput from 'components/Inputs/Checkbox';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import usePatternsSearchEngine from 'customHooks/searchEngine/usePatternsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import CreateProjectModalContentLayout
	from '../../../../components/Layouts/CreateProjectModalContentLayout/CreateProjectModalContentLayout';

const FabricDetailsProjectsView = ({ fabric }: { fabric: FabricDetailsFragment }) => {
	const navigate = useNavigate();
	const [page, setPage] = useState(1);
	const [filterOnType, setFilterOnType] = useState(!!fabric.type.length);
	const [filterOnQuantity, setFilterOnQuantity] = useState(!!fabric.quantity);
	const { notifyError } = useNotifications();
	const { data, loading } = usePatternsSearchEngine({
		limit: 6, overrideFilters: {
			page,
			recommendedFabrics: filterOnType ? fabric.type : null,
			patternType: [PatternType.Sewing],
			//TODO - adjust quantity
			fabricRequirement: filterOnQuantity ? fabric.quantity : null
		}
	});

	const [saveProject, { loading: savingProject }] = useSaveProjectMutation({
		onCompleted: ({ saveProject }) => {
			navigate(`/projects/${saveProject._id}`);
		},
		refetchQueries: [namedOperations.Query.getProjectsList],
		onError: error => notifyError(error.message)
	});
	return <FlexContainer column>
		{(loading || savingProject) && <LoadingOverlay/>}
		<Divider
			label={<Typography variant="subtitle1">{translate({
				key: 'Projects you can do with that fabric',
				data: { count: data?.count || 0 }
			})}</Typography>}>
		</Divider>
		<ActiveFiltersBox>
			<CheckboxInput
				checked={filterOnType}
				disabled={!fabric.type.length}
				onChange={(checked) => setFilterOnType(checked)}
				label={translate({ key: 'Composition' })}
				id="pattern-fabric-composition"/>
			<CheckboxInput
				checked={filterOnQuantity}
				disabled={!fabric.quantity}
				onChange={(checked) => setFilterOnQuantity(checked)}
				label={translate({ key: 'Quantity' })}
				id="pattern-fabric-quantity"/>
		</ActiveFiltersBox>
		{!!data?.patterns.length && (
			<ListLayout
				className="mt-1"
				items={data?.patterns || []}
				component={(item: PatternDetailsFragment) => <ListGridItem
					navigateLink={`/patterns/${item._id}`}
					item={{
						name: item.name,
						imageUrl: item.imageUrl
					}}
					deletable={false}
					projectCreatable
					initialProjectName={`${item.name} / ${fabric.name}`}
					modalTitle={'Project creation'}
					modalContent={<CreateProjectModalContentLayout
						headerText={'Create a project with these pattern and fabric'}
						patternImage={{ imageUrl: item.imageUrl, name: item.name }}
						stashImage={{ imageUrl: fabric.imageUrl, name: fabric.name }}
					/>}
					onModalConfirm={(name) => saveProject({
						variables: {
							input: {
								patternIds: [item._id],
								stashIds: [fabric.stashId],
								name
							}
						}
					})}
				/>}
				currentPage={1}
				total={data?.count}
				limit={12}
				onPageChange={page => setPage(page)}
			/>
		)}
		{!data?.patterns.length && `${translate({
			key: 'We found no patterns in your collection for that type and quantity of fabric'
		})
		}.`}
	</FlexContainer>;
};

export default FabricDetailsProjectsView;
