import { useCallback } from 'react';
import {
	InspirationDetailsFragment,
	InspirationInput,
	InspirationType,
	namedOperations,
	useSaveInspirationMutation
} from '__generated__/graphql';
import HostedImageInput from 'components/Inputs/ImageInput/HostedImageInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import FormContainerLayout from 'components/Layouts/FormContainerLayout/FormContainerLayout';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { FormProvider } from 'customHooks/formContexts/useInspirationFormContext';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { ValueOf } from 'types/core';
import { useImmerReducer } from 'use-immer';
import { InspirationFormReducer } from 'views/inspirations/InspirationForm/InspirationFormReducer';
import { FlexContainer } from '../../../components/base/FlexContainer';
import RadioInput from '../../../components/Inputs/RadioInput';
import VideoInput from '../../../components/Inputs/VideoInput';

const InspirationForm = ({
	inspiration,
	formTitle
}: { inspiration: InspirationDetailsFragment, formTitle: string }) => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const { loading: filterLoading, categories, tags, updateUserFilters } = useUserFilters();

	const [state, dispatch] = useImmerReducer(InspirationFormReducer, inspiration);
	const [saveInspiration, { loading }] = useSaveInspirationMutation({
		refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.getInspirationsList],
		onError: err => {
			notifyError(err.message);
		},
		onCompleted: ({ saveInspiration: savedInspiration }) => {
			if (savedInspiration) {
				navigate(`/inspirations/${savedInspiration._id}`);
			} else {
				notifyError('Some error occured');
			}
		}
	});

	const handleChange = useCallback(
		(key: keyof InspirationInput, value: ValueOf<InspirationInput>) => {
			dispatch({ key, value });
		}, [dispatch]
	);

	const onSubmit = useCallback(async () => {
		const { __typename, createdAt, currentProjectsIds, ...input } = state;
		await saveInspiration({
			variables: {
				input
			}
		});
	}, [saveInspiration, state]);

	return (
		<FormContainerLayout
			formTitle={formTitle}
			onSave={onSubmit}
			loading={loading}
			isValid={!!state.name && !!(state.imageUrl || state.videoUrl)}
			disabledMessage={translate({ key: 'Inspiration must have a name and a source' })}
		>
			<FormProvider value={{ state, handleChange }}>
				<FlexContainer className="w-100">
					<RadioInput
						options={[
							{ value: InspirationType.Image, label: translate({ key: 'Image' }) },
							{ value: InspirationType.Video, label: translate({ key: 'Video' }) },
						]}
						onChange={type => {
							handleChange('type', type);
							handleChange(InspirationType.Image ? 'videoUrl' : 'imageUrl', null);
						}}
						value={state.type}
						inputName="pattern-type-selector"/>
				</FlexContainer>
				<FormLayout
					imgContent={
						state.type === InspirationType.Image ? <HostedImageInput
							hosted={false}
							id="inspiration-image"
							onRemoteUrlChange={url => handleChange('imageUrl', url)}
							name={state._id || 'image'}
							remoteUrl={state.imageUrl}
						/> : <VideoInput videoUrl={state.videoUrl} onUrlChange={url => handleChange('videoUrl', url)}/>
					}
					mainContent={<GridContainer>
						<GridItem md={12}>
							<TextInput
								id="inspiration-name"
								className="pt-0"
								max={50}
								value={state.name}
								label={`${translate({ key: 'Name' })} *`}
								onChange={name => {
									if (name.length < 50) {
										handleChange('name', name);
									}
								}}
								validate={value => !!value && !!value.length}
								errorMessage={translate({ key: 'Name is mandatory' })}
							/>
						</GridItem>
						<GridItem md={12}>
							<MultiSelect
								label={translate({ key: 'Categories' })}
								onChange={(categories) => {
									handleChange('categories', categories);
								}}
								value={state.categories.map(value => ({ value, label: value }))}
								creatable
								onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
								data={categories.map(value => ({ value, label: value }))}/>
						</GridItem>
						<GridItem md={12}>
							<MultiSelect
								label={translate({ key: 'Tags' })}
								onChange={(tags) => {
									handleChange('tags', tags);
								}}
								value={state.tags.map(value => ({ value, label: value }))}
								creatable
								onCreate={(tag: string) => updateUserFilters({ tags: [tag] })}
								data={tags.map(value => ({ value, label: value }))}/>
						</GridItem>
						<GridItem md={12}>
							<TextEditor
								content={state.notes}
								handleChange={async (text) => handleChange('notes', text)}
								title={translate({ key: 'Notes' })}/>
						</GridItem>
					</GridContainer>}>
				</FormLayout>
			</FormProvider>
		</FormContainerLayout>
	);
};

export default InspirationForm;

InspirationForm.defaultProps = {
	formTitle: 'New inspiration',
	inspiration: {
		imageUrl: null,
		videoUrl: null,
		name: '',
		tags: [],
		notes: '',
		categories: [],
		type: InspirationType.Image
	}
};
