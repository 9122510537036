import { useCallback } from 'react';
import {
	namedOperations,
	ProjectStatus,
	useGetUserFiltersQuery,
	UserFiltersInput,
	useSaveUserFiltersMutation
} from '__generated__/graphql';
import useNotifications from 'customHooks/utils/useNotifications';
import { translate } from 'services/i18n';
import useIsDemo from './useIsDemo';

const DEFAULT_FABRICS = ['4-way-stretch', '2-way-stretch', 'woven'].map((value: string) => ({
	value,
	label: translate({ key: value })
}));
const DEFAULT_YARN_WEIGHTS = [
	'Lace',
	'Fingering',
	'Sock',
	'Sport',
	'DK',
	'Worsted',
	'Aran',
	'Bulky',
	'Chunky',
	'Medium',
	'Fine'
].map(value => ({ value, label: value }));

export const FORMATS: { value: string, label: string }[] = [
	{ value: 'a4', label: translate({ key: 'A4' }) },
	{ value: 'a4-layered', label: translate({ key: 'A4 with layers' }) },
	{ value: 'us-letter', label: translate({ key: 'US Letter' }) },
	{ value: 'us-letter-layered', label: translate({ key: 'US letter with layers' }) },
	{ value: 'a0', label: translate({ key: 'A0' }) },
	{ value: 'a0-layered', label: translate({ key: 'A0 with layers' }) },
	{ value: 'projector', label: translate({ key: 'Projector' }) },
	{ value: 'projector-layered', label: translate({ key: 'Projector with layers' }) },
	{ value: 'custom-projector', label: translate({ key: 'Custom projector' }) },
	{ value: 'paper', label: translate({ key: 'Paper' }) }
];

const UseUserFilters = () => {
	const PROJECT_STATUSES = [
		ProjectStatus.NotStarted,
		ProjectStatus.Started,
		ProjectStatus.Paused,
		ProjectStatus.ToAdjust,
		ProjectStatus.Finished,
		ProjectStatus.Abandoned
	].map(value => ({ value, label: translate({ key: value }) }));

	const { notifyError } = useNotifications();
	const isDemo = useIsDemo();
	const { data, loading } = useGetUserFiltersQuery({
		variables: {
			isDemo
		}
	});

	const [saveUserFilters, { loading: savingNewFilters }] = useSaveUserFiltersMutation();

	const updateUserFilters = useCallback(async (newFilters: UserFiltersInput) => {
		await saveUserFilters({
			variables: {
				input: newFilters
			},
			onError: ({ message }) => {
				notifyError(message);
			},
			refetchQueries: [namedOperations.Query.getUserFilters]
		});
	}, [notifyError, saveUserFilters]);


	return isDemo ? {
		loading: false,
		fabrics: DEFAULT_FABRICS,
		brands: [],
		availableFormats: FORMATS,
		tags: [],
		categories: [],
		patternLanguage: [],
		boughtIn: [],
		colors: [],
		designs: [],
		composition: [],
		yarnWeights: DEFAULT_YARN_WEIGHTS,
		projectStatuses: PROJECT_STATUSES,
		updateUserFilters,
		savingNewFilters,
		recipients: []
	} : {
		loading,
		fabrics: DEFAULT_FABRICS.concat((data?.userFilters?.recommendedFabrics || []).map(fabric => ({
			value: fabric,
			label: fabric
		}))),
		brands: data?.userFilters?.brands || [],
		availableFormats: FORMATS,
		categories: data?.userFilters?.categories || [],
		tags: data?.userFilters?.tags || [],
		patternLanguage: data?.userFilters?.patternLanguage || [],
		boughtIn: data?.userFilters?.boughtIn || [],
		colors: data?.userFilters?.colors || [],
		designs: data?.userFilters?.designs || [],
		composition: data?.userFilters?.composition || [],
		recipients: data?.userFilters?.recipients || [],
		yarnWeights: DEFAULT_YARN_WEIGHTS.concat((data?.userFilters?.recommendedYarnWeight || []).map(fabric => ({
			value: fabric,
			label: fabric
		}))),
		projectStatuses: PROJECT_STATUSES,
		updateUserFilters,
		savingNewFilters
	};
};

export default UseUserFilters;
