import { SubscriptionPlan, useCheckoutLazyQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import Loader from 'components/base/Loader';
import { Button } from 'components/Button/Button';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserProfile from 'customHooks/data/useUserProfile';
import { translate } from 'services/i18n';
import { Plan } from 'types/core';

const plans: Plan[] = [
	{
		name: SubscriptionPlan.Free, price: translate({ key: 'Free' }), limits: {
			projects: 10,
			patterns: 50,
			fabrics: 50
		}
	},
	{
		name: SubscriptionPlan.Medium, price: '2€', limits: {
			projects: 40,
			patterns: 200,
			fabrics: 200
		}
	},
	{
		name: SubscriptionPlan.Bulky, price: '5€', limits: {
			projects: 100,
			patterns: 500,
			fabrics: 500
		}
	},
	{
		name: SubscriptionPlan.Jumbo, price: '15€', limits: {
			projects: 2000,
			patterns: 2000,
			fabrics: 2000
		}
	},
];
const SubscriptionView = () => {
	const { profile, loadingProfile } = useUserProfile();

	const [getCheckoutUrl] = useCheckoutLazyQuery({
		onCompleted: ({ checkout }) => {
			window.location.replace(checkout);
		}
	});
	return loadingProfile ? <Loader/> : <FlexContainer>
		<GridContainer>
			{plans.map((plan) => (
				<GridItem
					xs={2} sm={4} md={3} key={plan.name}
					borderColor={profile?.subscription.plan === plan.name ? 'blue' : 'gray'}>
					<div>
						{plan.name.toUpperCase()}</div>
					<div>{plan.price}</div>
					<div>{`${plan.limits.patterns} ${translate({ key: 'patterns' })}`}</div>
					<div>{`${plan.limits.fabrics} ${translate({ key: 'fabrics' })}`}</div>
					<div>{`${plan.limits.projects} ${translate({ key: 'projects' })}`}</div>
					{plan.name !== SubscriptionPlan.Free && <div>
						<Button
							disabled={profile?.subscription.plan === plan.name}
							label={translate({ key: 'Subscribe' })}
							onClick={() => getCheckoutUrl({ variables: { plan: plan.name } })}
						/>
					</div>}
				</GridItem>))}
		</GridContainer>
	</FlexContainer>
	;
};

export default SubscriptionView;
