import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const httpLink = createHttpLink({
	uri: `${process.env.REACT_APP_API_URL}/graphql`,
	credentials: 'include'
});
const authLink = setContext((_, ctx) => ctx);

export const client = new ApolloClient({
	link: authLink.concat(httpLink),
	cache: new InMemoryCache(),
	credentials: 'include',
});
