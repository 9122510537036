import { FabricDetailsFragment, FabricInput, FileInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export const FabricFormReducer = (
	state: FabricDetailsFragment & { hostedImage?: FileInput },
	{ key, value }: { key: keyof FabricInput, value: ValueOf<FabricInput> }
) => {
	switch (key) {
	case 'name':
	case 'shop':
	case 'quantity':
	case 'type':
	case 'notes':
	case 'imageUrl':
	case 'hostedImage':
	case 'colors':
	case 'designs':
	case 'composition':
		return {
			...state,
			[key]: value
		};

	default:
		throw new Error(`No reducer available for key = ${key}`);
	}
};
