import { FileInput, PatternDetailsFragment, PatternInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export const PatternFormReducer = (state: PatternDetailsFragment & { hostedImage?: FileInput }, {
	key,
	value
}: { key: keyof PatternInput, value: ValueOf<PatternInput> }) => {
	switch (key) {
	case 'name':
	case 'brand':
	case 'categories':
	case 'selfDraftedPattern':
	case 'availableFormats':
	case 'tags':
	case 'recommendedFabrics':
	case 'boughtIn':
	case 'destinedTo':
	case 'patternLanguage':
	case 'shopPatternLink':
	case 'isFreePattern':
	case 'relevantVideos':
	case 'notes':
	case 'difficulty':
	case 'files':
	case 'hostedImage':
	case 'imageUrl':
	case 'fabricRequirement':
	case 'applicableSizeChart':
	case 'projectorAdjustmentRequired':
	case 'fittingAdjustmentRequired':
	case 'patternType':
	case 'needlesSize':
	case 'gauge':
	case 'recommendedYarnWeight':
		return {
			...state,
			[key]: value
		};

	default:
		throw new Error(`No reducer available for key = ${key}`);
	}
};
