import { lazy } from 'react';
import FabricDetailsView from 'views/fabrics/FabricDetailsView/FabricDetailsView';
import FabricsListView from 'views/fabrics/FabricsListView/FabricsListView';
import StashListView from 'views/stash/StashListView';
import YarnDetailsView from 'views/yarns/YarnDetailsView/YarnDetailsView';
import YarnsListView from 'views/yarns/YarnsListView/YarnsListView';
import InspirationDetailsView from '../views/inspirations/InspirationDetailsView/InspirationDetailsView';
import InspirationsListView from '../views/inspirations/InspirationsListView/InspirationsListView';
import PatternCreationView from '../views/patterns/PatternCreationView/PatternCreationView';
import ProjectDetailsView from '../views/projects/ProjectDetailsView/ProjectDetailsView';
import ProjectsListView from '../views/projects/ProjectsListView/ProjectsListView';

const PatternDetailsView = lazy(() => import( 'views/patterns/PatternDetailsView/PatternDetailsView'));
const PatternsListView = lazy(() => import( 'views/patterns/PatternsListView/PatternsListView'));

export default [
	{ path: 'patterns', name: 'My patterns', Component: PatternsListView },
	{ path: 'patterns/sewing', name: 'Sewing patterns', Component: PatternsListView },
	{ path: 'patterns/knit', name: 'Knit patterns', Component: PatternsListView },
	{ path: 'patterns/crochet', name: 'Crochet patterns', Component: PatternsListView },
	{ path: 'patterns/other', name: 'Other patterns', Component: PatternsListView },
	{
		path: 'patterns/:id',
		name: 'Pattern details',
		Component: PatternDetailsView,
		breadcrumbs: [{ name: 'My patterns', link: '/demonstration/patterns' }]
	},
	{
		path: 'patterns/new',
		name: 'New pattern',
		Component: PatternCreationView,
		breadcrumbs: [{ name: 'My patterns', link: '/demonstration/patterns' }]
	},
	{ path: 'stash', name: 'My stash', Component: StashListView },
	{ path: 'fabrics', name: 'Fabrics', Component: FabricsListView },
	{
		path: 'fabrics/:id', name: 'Fabric details', Component: FabricDetailsView,
		breadcrumbs: [{ name: 'My fabrics', link: '/demonstration/fabrics' }]
	},
	{ path: 'yarn', name: 'Yarn', Component: YarnsListView },
	{
		path: 'yarn/:id', name: 'Yarn details', Component: YarnDetailsView,
		breadcrumbs: [{ name: 'My yarn', link: '/demonstration/yarn' }]
	},
	{ path: 'inspirations', name: 'Inspirations', Component: InspirationsListView },
	{
		path: 'inspirations/:id',
		name: 'Inspiration details',
		Component: InspirationDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/demonstration/inspirations' }]
	},
	{ path: 'projects', name: 'My projects', Component: ProjectsListView },
	{
		path: 'projects/:id', name: 'Project details', Component: ProjectDetailsView,
		breadcrumbs: [{ name: 'My projects', link: '/demonstration/projects' }]
	},
];
