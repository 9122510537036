import styled from 'styled-components';

export const StyledAdminPageSidebar = styled.div`
    z-index: 300;
    background: ${({ theme }) => theme.colors.white};
    padding: 1em;
    width: 18em;
    color: ${({ theme }) => theme.colors.darkgrey};
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2em);
    position: fixed;
    border-right: 1px solid ${({ theme }) => theme.colors.lightgrey};
    @media screen and (max-width: 1200px) {
      height: calc(100% - 6em);
      width: 16em;
    }
    @media screen and (max-width: 767px) {
      height: calc(100% - 6em);
      width: 100%;
    }
`;

export const StyledLogoContainer = styled.div`
    height: 6em;
    display: flex;
    align-content: center;
    justify-content: center;
    font-size: 2em!important;
    text-shadow: 2px 2px 2px ${({ theme }) => theme.colors.darkgrey};
    font-weight: bold;
    font-family: Parisienne;
    
    a {
      line-height: 1em;
      height: 2em;
      color: white;
      &:hover {
        color: lightcyan;
      }
    }
`;
interface IStyledMenuItem {
    sub?: boolean
}
export const StyledAdminPageSidebarMenu = styled.div`
    height: calc(100% - 12em);
`;

export const StyledAdminPageSidebarMenuItem = styled.div<IStyledMenuItem>`
        text-align: left!important;
    a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.darkgrey};
        &:hover {
            color: ${({ theme }) => theme.colors.lightgrey};
        }
    }
    padding-left: ${({ sub }) => sub ? '1.5em' : 0};
    padding-bottom:1em;
`;

export const StyledHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 1em;
  font-weight: bold;
  border-bottom: 1px solid;
`;
