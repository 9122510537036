import React, { useCallback, useState } from 'react';
import { faDownload, faImage } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FileDetailsFragment } from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import useImageCompressor from 'customHooks/services/useImageCompressor';
import { uploadImage } from 'services/api/rest/filesService';
import { translate } from 'services/i18n';
import { StyledImageInput, StyledImageInputLoader, StyledLabelContainer } from './StyledImageInput';
import { Button } from '../../Button/Button';
import TextInput from '../TextInput';

interface ImageInputProps {
	id: string
	image?: null | Partial<FileDetailsFragment>
	onChange?: (value: FileDetailsFragment | null) => void,
	onRemoteUrlChange: (value: string | null) => void
	alt?: string,
	name: string,
	hosted?: boolean
	remoteUrl?: string
}


const HostedImageInput = ({
	id,
	image,
	onChange,
	alt,
	name,
	hosted,
	remoteUrl,
	onRemoteUrlChange
}: ImageInputProps) => {
	const { compressImage } = useImageCompressor();
	const [imageVersion, setImageVersion] = useState<number>(1);
	const [loading, setLoading] = useState<boolean>(false);
	const onInputChange = useCallback(async (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target?.files?.length) {
			const compressedImage = await compressImage(e.target.files[0]) as Blob;
			setLoading(true);
			const uploadedImage = await uploadImage(compressedImage, name, hosted);
			setLoading(false);
			setImageVersion(imageVersion => imageVersion + 1);
			onChange(uploadedImage);
			if (remoteUrl) {
				onRemoteUrlChange(null);
			}
		}
	}, [onChange, compressImage, name, hosted, onRemoteUrlChange, remoteUrl]);

	return (
		<FlexContainer column>
			<TextInput
				className={hosted ? '' : 'mb-1'}
				value={remoteUrl || ''}
				onChange={(url) => {
					onRemoteUrlChange(url);
					if (image) {
						onChange(null);
					}
				}}
				id="remote-img-url"
				label={`${translate({ key: 'Url' })} *`}
				placeholder={translate({ key: 'Image url' })}
			/>
			{hosted && <Divider label={translate({ key: 'Or' })}/>}
			<StyledImageInput column>
				<input
					id={id}
					type="file"
					accept="image/png, image/jpeg, image/*"
					onChange={onInputChange}
					disabled={!hosted}/>
				<label htmlFor={id}>
					{loading && <StyledImageInputLoader/>}
					{(image || remoteUrl) ? <>
						<RemoteImage
							alt={alt || translate({ key: 'New image' })}
							src={remoteUrl || `${image.url}?v=${imageVersion}`}
						/>
						<FlexContainer className="overlay" column>
							{hosted && <Button
								sx={{ pointerEvents: 'none!important' }}
								className="mb-1"
								label={translate({ key: 'Edit' })}
							/>}
							<Button
								onClick={() => onChange(null)}
								label={translate({ key: 'Delete' })}
								red
							/>
						</FlexContainer>
					</> : (
						<StyledLabelContainer loading={loading} className="label-container">
							<FontAwesomeIcon icon={hosted ? faDownload : faImage}/>
						</StyledLabelContainer>
					)}
				</label>
			</StyledImageInput>
		</FlexContainer>
	);
};

HostedImageInput.defaultProps = {
	hosted: true
};

export default HostedImageInput;
