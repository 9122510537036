import { toast, } from 'react-toastify';
import { translate } from 'services/i18n';

const UseNotifications = () => {
	return {
		notifyError: (message: string) => {
			toast.error(`${translate({ key: 'Sorry, an error occurred' })} : ${message}`);
		},
		notifyInfo: (message: string) => {
			toast.info(message);
		}
	};
};

export default UseNotifications;
