import { useState } from 'react';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import TextInput from 'components/Inputs/TextInput';
import { sendForgetPassword } from 'services/api/rest/accountService';
import { translate } from 'services/i18n';
import { isValidEmail } from 'services/utils';

const ForgetPasswordView = () => {
	const [email, setEmail] = useState<string>('');
	const [result, setResult] = useState<string| null>(null);

	return (
		<FlexContainer column>
			<FlexContainer column className="w-50 w-md-75 w-sm-100">
				<h1>{`${translate({ key: 'Forgot your password' })} ?`}</h1>
				<TextInput
					id={'reset-email'}
					label={translate({ key: 'Email' })}
					onChange={(email:string) => setEmail(email)}
					value={email || ''}
					validate={(email: string) => !email.length || isValidEmail(email)}
					errorMessage={translate({ key: 'Invalid email' })}
				/>
				<Button
					label={translate({ key: 'Reset password' })}
					onClick={() => {
						if (email) {
							sendForgetPassword(email).then(() => {
								setResult(translate({ key: 'If an account exists for that email, a reset link has been sent to you.' }));
							});
						}
					}}
					disabled={!isValidEmail(email)}
				/>
			</FlexContainer>
			<div>{result}</div>
		</FlexContainer>
	);
};

export default ForgetPasswordView;
