import { useCallback, useMemo } from 'react';
import { useGetInspirationsListQuery } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import useIsDemo from '../data/useIsDemo';

export interface InspirationFilters {
	name: string | null;
	tags: string[];
	categories: string[];
	page: number | null;
}

const useInspirationsSearchEngine = ({ limit }: { limit?: number } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const [searchParams, setSearchParams] = useSearchParams();
	const currentFilters = useMemo(() => {
		const tags = searchParams.get('tags');
		const name = searchParams.get('name');
		const categories = searchParams.get('categories');
		const page = searchParams.get('page');

		return {
			tags: tags?.split(',') || [],
			categories: categories?.split(',') || [],
			page: page ? +page : 1,
			name,
		};
	}, [searchParams]);
	const { loading, error, data, refetch, fetchMore } = useGetInspirationsListQuery({
		variables: {
			...currentFilters,
			limit: LIMIT,
			isDemo
		}
	});

	const updateFilterValues = useCallback((field: keyof InspirationFilters, newValue: string | number | null) => {
		const updatedFilters = Object.entries({
			...currentFilters,
			[field]: newValue ? newValue : null
		}).reduce((updatedFilters, [key, value]) => {
			return typeof value === 'number' || (typeof value === 'string' && value[0]) ?
				Object.assign(updatedFilters, { [key]: value.toString() }) : updatedFilters;
		}, {});

		setSearchParams(updatedFilters);
		refetch();
	}, [currentFilters, setSearchParams, refetch]);

	const updateUrl = useCallback((field: keyof InspirationFilters, newValue: string | number | null) => {
		switch (field) {
		case 'tags':
		case 'categories':
		case 'page':
		case 'name':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters,
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		fetchMore,
		data: data?.inspirations
	};
};

export default useInspirationsSearchEngine;
