import { namedOperations, useDeleteProjectMutation, useGetProjectQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import DetailsLayout from 'components/Layouts/DetailsLayout/DetailsLayout';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { translate } from 'services/i18n';
import ProjectDetailsDescriptionView from './ProjectDetailsDescriptionView/ProjectDetailsDescriptionView';
import ProjectDetailsRelatedEntitiesView from './ProjectDetailsRelatedEntitiesView/ProjectDetailsRelatedEntitiesView';
import ProjectImage from '../../../components/ProjectImage/ProjectImage';
import DeleteProjectModal from '../DeleteProjectModal/DeleteProjectModal';

const ProjectDetailsView = () => {
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const { notifyError } = useNotifications();

	const { loading, data } = useGetProjectQuery(
		{
			variables: { projectId: id },
			onError: err => {
				notifyError(err.message);
			}
		});
	const [deleteProject, { loading: deletingProject }] = useDeleteProjectMutation({
		refetchQueries: [namedOperations.Query.getProjectsList],
		onCompleted: () => {
			navigate('/projects');
		}
	});

	return <FlexContainer column>
		{(loading || deletingProject) && <LoadingOverlay/>}
		{data?.project && (
			<DetailsLayout
				deleteModalTitle={translate({ key: 'Project deletion' })}
				onDeleteConfirm={() => deleteProject({ variables: { projectId: data.project._id } })}
				deleteModalContent={<DeleteProjectModal project={data.project}/>}
				editLink={`/projects/${data.project._id}/edit`}
				itemName={data.project.name}
				notes={data.project.notes}
				imgContent={<ProjectImage project={data.project}/>}
				mainContent={<ProjectDetailsDescriptionView project={data.project}/>}
				relatedEntitiesContent={<ProjectDetailsRelatedEntitiesView project={data.project}/>}
				relatedEntitiesTitle={translate({ key: 'Related elements' })}
			/>)
		}
	</FlexContainer>;
};

export default ProjectDetailsView;
