import { useCallback, useMemo } from 'react';
import { QueryStashArgs, useGetStashListQuery } from '__generated__/graphql';
import { useSearchParams } from 'react-router-dom';
import { ValueOf } from 'types/core';
import useIsDemo from '../data/useIsDemo';


const useStashSearchEngine = ({ limit }: { limit?: number } = {}) => {
	const isDemo = useIsDemo();
	const LIMIT = limit || 12;
	const [searchParams, setSearchParams] = useSearchParams();
	const currentFilters = useMemo(() => {
		const page = searchParams.get('page');
		const name = searchParams.get('name');

		return {
			page: page ? +page : 1,
			name,
		};
	}, [searchParams]);

	const { loading, error, data, refetch, fetchMore } = useGetStashListQuery({
		variables: { ...currentFilters, limit: LIMIT, isDemo }
	});

	const updateFilterValues = useCallback((field: keyof QueryStashArgs, newValue: ValueOf<QueryStashArgs>) => {
		const updatedFilters = Object.entries({
			...currentFilters,
			[field]: newValue ? newValue : null
		}).reduce((updatedFilters, [key, value]) => {
			return typeof value === 'number' || (typeof value === 'string' && value[0]) ?
				Object.assign(updatedFilters, { [key]: value.toString() }) : updatedFilters;
		}, {});

		setSearchParams(updatedFilters);
		refetch();
	}, [currentFilters, setSearchParams, refetch]);

	const updateUrl = useCallback((field: keyof QueryStashArgs, newValue: ValueOf<QueryStashArgs>) => {
		switch (field) {
		case 'page':
		case 'name':
			return updateFilterValues(field, newValue);
		default:
			throw new Error(`Field not allowed ${field}`);
		}
	}, [updateFilterValues]);

	return {
		currentFilters,
		loading,
		error,
		updateUrl,
		limit: LIMIT,
		fetchMore,
		data: data?.stash
	};
};

export default useStashSearchEngine;
