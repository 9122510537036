import { createContext, ReactNode } from 'react';

const DemonstrationContext = createContext<{ isDemo: boolean } | null>(null);

const DemonstrationProvider = ({ children }: { children: ReactNode }) => {

	return (
		<DemonstrationContext.Provider value={{ isDemo: true }}>
			{children}
		</DemonstrationContext.Provider>
	);
};

export { DemonstrationContext, DemonstrationProvider };
