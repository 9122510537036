export const en = {
	'New pattern': 'Nouveau patron',
	'Pattern name': 'Nom du patron',
	'Brand': 'Marque',
	'Notes': 'Notes',
	'My patterns': 'My patterns',
	'Add new pattern': 'Ajouter un patron',
	'Feedbacks': 'Suggestions',
	'Guide': 'Guide',
	'Brands': 'Marques',
	'Remember me': 'Se souvenir de moi',
	'Login': 'Connexion',
	'Username': 'Nom d\'utilisateur',
	'Password': 'Mot de passe',
	'Forgot your password': 'Mot de passe oublié',
	'Profile': 'Profil',
	'No account yet ? Sign in': 'Pas encore de compte? Inscrivez-vous',
	'here': 'ici',
	'Connect': 'Se connecter',
	'About us': 'A propos',
	'Contact': 'Contact',
	'Sign in': 'Création de compte',
	'Create account': 'Créer le compte',
	'Wishlist': 'Liste de souhaits',
	'No account found for the provided credentials': 'Email et/ou mot de passe incorrect(s)',
	'Image URL': 'URL de l\'image',
	'Categories': 'Catégories',
	'Self drafted pattern': 'Patron personnel',
	'Projector': 'Projecteur',
	'A4 with layers': 'A4 avec calques',
	'US Letter with layers': 'US Letter avec calques',
	'A0 with layers': 'A0 avec calques',
	'Projector with layers': 'Projecteur avec calques',
	'Custom projector file': 'Projecteur personnalisé',
	'Available formats': 'Formats disponibles',
	'Tags': 'Tags',
	'Recommended fabrics': 'Tissus conseillés',
	'Bought in': 'Acheté en',
	'Destined to': 'Destiné à',
	'Everyone': 'Tout le monde',
	'Women': 'Femmes',
	'Men': 'Hommes',
	'Children': 'Enfants',
	'Language': 'Langue',
	'Difficulty': 'Difficulté',
	'Shop pattern link': 'Lien du patron sur la boutique',
	'Free pattern': 'Patron gratuit',
	'Video name': 'Nom de la vidéo',
	'Video link': 'Lien de la vidéo',
	'My inspirations': 'Mes inspirations',
	'My fabrics': 'Mes tissus',
	'Files': 'Fichiers',
	'File name': 'Nom du fichier',
	'File link': 'Lien du fichier',
	'Save pattern': 'Sauvegarder le patron',
	'Custom filters': 'Filtres personnalisés',
	'Logout': 'Se déconnecter',
	'Profile settings': 'Paramètres du compte',
	'Filters': 'Filtres',
	'Advanced filters': 'Filtres avancés',
	'Search results': ' Résultats de recherche',
	'No results': 'Aucun résultat',
	'NO IMAGE AVAILABLE': 'PAS D\'IMAGE DISPONIBLE',
	'Validate': 'Valider',
	'Paper': 'Papier',
	'Main fabric requirement': 'Métrage tissu principal',
	'Lining fabric requirement': 'Métrage doublure',
	'Applicable size chart': 'Taille patron à utiliser',
	'OR': 'OU',
	'Name is mandatory': 'Veuillez remplir le nom',
	'Click to browse or drop image': 'Cliquez pour parcourir ou faites glisser une image',
	'a4': 'A4',
	'a4-layered': 'A4 avec calques',
	'us-letter': 'US Letter',
	'us-letter-layered' : 'US Letter avec calques',
	'a0': 'A0',
	'a0-layered': 'A0 avec calques',
	'projector':'Projecteur',
	'projector-layered':'Projecteur avec calques',
	'custom-projector':'Projecteur personnalisé',
	'paper':'Papier',
	'Fabric requirements': 'Métrages requis',
	'Main fabric': 'Tissu principal',
	'Lining fabric': 'Doublure',
	'Fabric details': 'Détails tissu',
	'My notes': 'Mes notes',
	'My files': 'Mes fichiers',
	'My videos': 'Mes vidéos',
	'Username already taken': 'Nom d\'utilisateur non disponible',
	'Username updated': 'Nom d\'utilisateur modifié',
	'You must provide the link of the video': 'Saisissez l\'url de la vidéo',
	'Invalid email': 'Email non valide',
	'Reset password': 'Réinitialiser le mot de passe',
	'Video url': 'URL de la vidéo',
	'File url': 'URL du fichier',
	'Click to edit': 'Cliquez pour modifier',
	'Create an account': 'Créer un compte',
	'Create': 'Créer',
	'No result for that search': 'Pas de résultat pour cette recherche',
	'My library': 'Ma bibliotèque',
	'Resources': 'Ressources',
	'Demonstration': 'Démonstration',
	'My brands': 'Mes marques',
	'Add new fabric': 'Ajouter un tissu',
	'New fabric': 'Nouveau tissu',
	'Name': 'Nom',
	'Shop': 'Boutique',
	'Available quantity': 'Quantité disponible',
	'Fabric types': 'Types de tissu',
	'4-way-stretch': 'Bi-stretch',
	'2-way-stretch': 'Stretch',
	'woven': 'Chaine et trame',
	'Type to search or create': 'Tapez pour chercher ou créer',
	'Brand name': 'Nom de la marque',
	'Brand website url': 'Site de la marque',
	'Brands list':'Annuaire des marques'
};
