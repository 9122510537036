import { Box } from '@mui/material';
import { ProjectDetailsFragment } from '__generated__/graphql';
import styled from 'styled-components';
import { FlexContainer } from '../base/FlexContainer';
import RemoteImage from '../base/RemoteImage';

interface ProjectImageProps {
	project: ProjectDetailsFragment;
}

const StyledProjectImage = styled.div`
  width: 100%;
  ratio: 3 / 4;
  position: relative;
`;

const StyledStashItemsContainer = styled(FlexContainer)`
  position: absolute;
  right: 0;
  bottom: 3em;
  width: inherit;
  padding: 0.2em;
  justify-content: flex-end;
`;

const ProjectImage = ({ project }: ProjectImageProps) => {
	const backgroundImage = project.inspirations.length ? project.inspirations[0].imageUrl : project.patterns.length ? project.patterns[0].imageUrl : null;
	const stashImages = project.stash.slice(0, 4);
	return (
		<StyledProjectImage>
			<RemoteImage src={backgroundImage} alt={project.name}/>
			<StyledStashItemsContainer>
				{(project.stash || []).filter(({ imageUrl }) => imageUrl).slice(0, 4).map(item => <Box
					component="img"
					sx={{
						borderRadius: '200px',
						width: stashImages.length <= 2 ? '45%' : '23%',
						aspectRatio: '1 / 1'
					}}
					src={item.imageUrl}
					key={item.resourceId}
					alt={item.name}/>)}
			</StyledStashItemsContainer>
		</StyledProjectImage>

	);
};

export default ProjectImage;
