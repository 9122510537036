import { Grid, TextField } from '@mui/material';
import { translate } from 'services/i18n';

interface FileInputProps {
	onChange: (e: { url?: string, name?: string }) => void;
	url: string;
	name: string;
}

const FileInput = ({ onChange, name, url }: FileInputProps) => {
	return (
		<Grid container spacing={2}>
			<Grid item xs={12} md={6}>
				<TextField
					label={translate({ key: 'File name' })}
					className="w-100"
					sx={{
						label: {
							fontWeight: 'normal'
						}
					}}
					value={name || ''}
					onChange={(e) => onChange({ name: e.target.value, url })}/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					label={translate({ key: 'File url' })}
					className="w-100"
					sx={{
						label: {
							fontWeight: 'normal'
						}
					}}
					value={url || ''}
					onChange={e => onChange({ name, url: e.target.value })}/>
			</Grid>
		</Grid>
	);
};

export default FileInput;
