import { PatternDetailsFragment } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useIsDemo from 'customHooks/data/useIsDemo';
import usePatternsSearchEngine from 'customHooks/searchEngine/usePatternsSearchEngine';
import { translate } from 'services/i18n';
import DeletePatternModal from '../../DeletePatternModal/DeletePatternModal';

const PatternsListResultsView = () => {
	const isDemo = useIsDemo();
	const { currentFilters, data, updateUrl, limit, loading } = usePatternsSearchEngine();

	return <div className={'w-100'}>
		{loading && <LoadingOverlay/>}
		{!data?.patterns.length && <span>{translate({ key: 'No results' })}</span>}
		{data?.patterns && <ListLayout
			items={data.patterns}
			onPageChange={page => updateUrl('page', page.toString())}
			total={data.count || 0}
			limit={limit}
			currentPage={currentFilters.page || 1}
			component={(pattern: PatternDetailsFragment) => <ListGridItem
				key={pattern._id}
				item={pattern}
				navigateLink={`${isDemo ? '/demonstration' : ''}/patterns/${pattern._id}`}
				modalContent={<DeletePatternModal pattern={pattern}/>}
				modalTitle={'Delete pattern'}
			/>}
		/>}
	</div>;
};

export default PatternsListResultsView;
