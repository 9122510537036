import { en } from 'assets/translations/en';
import { fr } from 'assets/translations/fr';
import { changeLanguage, t, use } from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

export function initTranslator() {
	const preferredLanguage = window.localStorage.getItem('preferredLanguage');
	const options = {
		resources: {
			fr: { translation: fr },
			en: { translation: en }
		},
		fallbackLng: ['fr', 'en'],
		lng: preferredLanguage || 'fr',
		supportedLngs: ['fr', 'en', 'ita']
	};
	return use(LanguageDetector).init(options);
}

export function changeUserLanguage(language: string) {
	return changeLanguage(language);
}

export function translate({
	key,
	data = {},
	defaultKey = null,
	country = null
}: { key: string, data?: object, defaultKey?: string | null, country?: string | null }) {
	let computedKey = key;
	let computedDefaultKey = defaultKey;
	if (country) {
		computedKey = `${key} ${country}`;
		computedDefaultKey = computedDefaultKey && `${defaultKey} ${country}`;
	}
	const keys = [computedKey, computedDefaultKey].reduce<string[]>((keys: string[], key: string | null) => key ? keys.concat(key.replace(/[.]/g, '_')) : keys, []);
	return t(keys, data);
}
