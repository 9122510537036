import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import ImageList from 'components/Layouts/ListLayout/ImageList';
import useInspirationsSearchEngine from 'customHooks/searchEngine/useInspirationsSearchEngine';
import InspirationsListFiltersView from './InspirationsListFiltersView';
import { InspirationType } from '../../../__generated__/graphql';

//TODO - masonry images list
const InspirationsListView = () => {
	const { data, loading } = useInspirationsSearchEngine();
	return (
		<FlexContainer column>
			{loading && <LoadingOverlay/>}
			<InspirationsListFiltersView/>
			<ImageList
				images={(data?.inspirations || []).map(({ name, imageUrl, videoUrl, _id, type }) => ({
					name,
					url: type === InspirationType.Image ? imageUrl : videoUrl,
					navigateLink: `/inspirations/${_id}`,
					type
				}))}
			/>
		</FlexContainer>
	);
};

export default InspirationsListView;
