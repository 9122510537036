import { ReactNode } from 'react';
import { Button as MuiButton, ButtonProps as MuiButtonProps, Tooltip } from '@mui/material';
import useIsDemo from '../../customHooks/data/useIsDemo';
import { translate } from '../../services/i18n';

interface ButtonProps extends MuiButtonProps {
	label: string,
	disabled: boolean
	onClick?: any/*() => void*/
	className?: string
	red?: boolean
	loading?: boolean
	icon?: ReactNode,
	disableIfDemo?: boolean
	disabledMessage?: string;
}

export const Button = ({
	label,
	disabled,
	onClick,
	className,
	red,
	loading,
	icon,
	disableIfDemo,
	disabledMessage,
	...props
}: ButtonProps) => {
	const isDemo = useIsDemo();
	return (
		<Tooltip title={disableIfDemo && isDemo ? translate({ key: 'Disabled in demo mode' }) : disabledMessage}>
			<span className="w-fit-content">
				<MuiButton
					{...props}
					color={red ? 'error' : 'inherit'}
					variant="outlined"
					className={`button ${className || ''}`}
					disabled={disabled || loading || (disableIfDemo && isDemo)}
					onClick={(e) => {
						e.preventDefault();
						e.stopPropagation();
						onClick(e);
					}}
					{...icon ? { startIcon: icon } : {}}
					sx={{
						...props.sx,
						borderRadius: '0.5em',
					}}
				>
					{label}
					{/*loading ? <Loader size={'sm'}/> : label*/}
				</MuiButton>
			</span>
		</Tooltip>
	);
};

Button.defaultProps = {
	red: false,
	disabled: false
};
