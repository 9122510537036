import { useYarnQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate, useParams } from 'react-router-dom';
import { YarnForm } from 'views/yarns/YarnForm/YarnForm';

const YarnEditView = () => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const { id } = useParams<'id'>();
	const { loading, data } = useYarnQuery({
		variables: { id },
		onError: err => {
			notifyError(err.message);
		}
	});

	return (
		<FlexContainer column className="align-items-start">
			{loading && <LoadingOverlay/>}
			{data?.yarn && <YarnForm yarn={data.yarn}/>}
		</FlexContainer>
	);
};

export default YarnEditView;
