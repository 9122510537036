import { FormControl, FormLabel, TextField } from '@mui/material';

interface DateInputProps {
	label: string;
	value?: Date | null;
	onChange: (value: Date | null) => void;
}

const formatDate = (date: Date) => `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
const stringToDate = (stringDate: string) => {
	if (!stringDate.length) {
		return null;
	}
	const dateParts = stringDate.split('-').map(part => +part);
	return new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
};

const DateInput = ({ label, value, onChange }: DateInputProps) => {
	return (
		<FormControl className="w-100">
			<FormLabel>{label}</FormLabel>
			<TextField
				type="date"
				value={value ? formatDate(value) : null}
				onChange={e => onChange(stringToDate(e.target.value))}/>
		</FormControl>
	);
};

export default DateInput;
