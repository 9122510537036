import { QuantityUnit } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import MultiSelect from 'components/Inputs/MultiSelect';
import QuantityInput from 'components/Inputs/QuantityInput';
import SearchInput from 'components/Inputs/SearchInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import useFabricsSearchEngine from 'customHooks/searchEngine/useFabricsSearchEngine';
import { translate } from 'services/i18n';


const FabricsListFiltersView = () => {
	const { currentFilters, updateUrl } = useFabricsSearchEngine();
	const { loading, fabrics, colors, designs, composition } = useUserFilters();

	return (
		<div className="pt-1 w-100">
			{loading && <LoadingOverlay/>}
			<GridContainer>
				<GridItem>
					<SearchInput
						className="pt-0"
						label={translate({ key: 'Name' })}
						value={currentFilters.name || ''}
						onChange={name => {
							updateUrl('name', name);
						}}
						id={'fabric-search-name'}
					/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Type' })}
						onChange={(type) => updateUrl('type', type.join(','))}
						data={fabrics}
						value={(currentFilters.type || []).map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
				<GridItem>
					<QuantityInput
						label="Available quantity"
						onChange={({ quantity, unit }) => {
							updateUrl('quantity', quantity.toString());
							//TODO - adjust
							//updateUrl('quantityUnit', unit);
						}
						}
						quantity={currentFilters.quantity}
						unit={QuantityUnit.Meters}/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Colors' })}
						onChange={colors => updateUrl('colors', colors.join(','))}
						data={colors.map(value => ({ value, label: value }))}
						value={currentFilters.colors.map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Design/Pattern' })}
						onChange={designs => updateUrl('designs', designs.join(','))}
						data={designs.map(value => ({ value, label: value }))}
						value={currentFilters.designs.map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
				<GridItem>
					<MultiSelect
						label={translate({ key: 'Composition' })}
						onChange={composition => updateUrl('composition', composition.join(','))}
						data={composition.map(value => ({ value, label: value }))}
						value={(currentFilters.composition || []).map(value => ({ value, label: value })) || []}
					/>
				</GridItem>
			</GridContainer>
		</div>
	);
};

export default FabricsListFiltersView;
