import { useCallback } from 'react';
import {
	StashDetailsFragment,
	StashItemType,
	useDeleteFabricMutation,
	useDeleteYarnMutation
} from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import RemoteImage from 'components/base/RemoteImage';
import TextInput from 'components/Inputs/TextInput';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import useStashSearchEngine from 'customHooks/searchEngine/useStashSearchEngine';
import { translate } from 'services/i18n';

const StashListView = () => {
	const { data, loading, updateUrl, currentFilters } = useStashSearchEngine({ limit: 12 });
	const [deleteFabric, { loading: deletingFabric }] = useDeleteFabricMutation();
	const [deleteYarn, { loading: deletingYarn }] = useDeleteYarnMutation();
	const deleteStashItem = useCallback((item: StashDetailsFragment) => {
		switch (item.itemType) {
		case StashItemType.Fabric:
			return deleteFabric({ variables: { fabricId: item.resourceId } });
		case StashItemType.Yarn:
			return deleteYarn({ variables: { yarnId: item.resourceId } });
		default:
			return;
		}
	}, [deleteYarn, deleteFabric]);
	//TODO - styling
	return (
		<FlexContainer column alignitems="start">
			{(loading || deletingFabric || deletingYarn) && <LoadingOverlay/>}
			<div className="w-sm-100 w-50">
				<TextInput
					value={currentFilters.name}
					onChange={name => updateUrl('name', name)}
					id="stash-name-search"
					label={translate({ key: 'Name' })}/>
			</div>
			<Divider
				sx={{ marginTop: '2em' }}
				label={<strong>
					{translate({ key: 'Search results', data: { count: data?.count || 0 } })}
				</strong>}/>
			<ListLayout
				className="pt"
				items={(data?.items || []).map(item => ({
					name: item.name,
					imageUrl: item.imageUrl,
					resourceId: item.resourceId,
					itemType: item.itemType
				}))}
				component={(item: StashDetailsFragment) => <ListGridItem
					key={item.resourceId}
					item={item}
					navigateLink={item.itemType === StashItemType.Yarn ? `/yarn/${item.resourceId}` : `/fabrics/${item.resourceId}`}
					modalTitle={translate({ key: 'Item deletion' })}
					onModalConfirm={() => deleteStashItem(item)}
					modalContent={<FlexContainer column>
						<div style={{ alignSelf: 'flex-start' }}>
							{`${translate({ key: 'Are you sure you want to delete this item' })} : ${
								item.name} ?`}
						</div>
						{(item.imageUrl) && <FlexContainer className={'w-50 pt-1'}>
							<div className="w-50"><RemoteImage src={item.imageUrl} alt={item.name}/></div>
						</FlexContainer>}
					</FlexContainer>}
				/>}
				total={data?.count || 0}
				currentPage={currentFilters.page}
				limit={12}
				onPageChange={pageNumber => updateUrl('page', pageNumber)}
			/>
		</FlexContainer>
	);
};

export default StashListView;
