import { getTiktokVideoId } from 'services/api/rest/tiktok';

const YOUTUBE_EMBED_BASE_URL = 'https://www.youtube.com/embed/';
const TIKTOK_EMBED_BASE_URL = 'https://www.tiktok.com/embed/v2/';
const INSTAGRAM_EMBED_BASE_URL = (videoId: string) => `https://www.instagram.com/p/${videoId}/embed/`;

const UseVideoUrlParser = () => {
	return {
		parseVideoUrl: async ({ videoUrl }: { videoUrl: string }) => {
			try {
				console.log(videoUrl);
				const url = new URL(videoUrl);
				switch (url.hostname) {
				case 'youtu.be': {
					const videoId = url.pathname.slice(1);
					return { videoUrl: YOUTUBE_EMBED_BASE_URL + videoId, embeddedHTML: null, provider: 'Youtube' };
				}
				case 'www.youtube.com' : {
					if (url.pathname === '/watch') {
						const videoId = url.searchParams.get('v');
						return { videoUrl: YOUTUBE_EMBED_BASE_URL + videoId, embeddedHTML: null, provider: 'Youtube' };
					}
					return { videoUrl, embeddedHTML: null, provider: null };
				}
				case 'www.tiktok.com': {
					return getTiktokVideoId(videoUrl).then((videoId) => {
						return { videoUrl: TIKTOK_EMBED_BASE_URL + videoId, embeddedHTML: null, provider: 'TikTok' };
					});
				}
				case 'www.instagram.com': {
					const videoId = url.pathname.split('/').filter(part => !!part).reverse()[0];
					return { videoUrl: INSTAGRAM_EMBED_BASE_URL(videoId), embeddedHTML: null, provider: 'Instagram' };
				}
				default: {
					return { videoUrl, embeddedHTML: null, provider: null };
				}

				}
			} catch (err) {
				console.log(err);
				return { videoUrl, embeddedHTML: null, provider: null };
			}
		}
	};

};

export default UseVideoUrlParser;
