import { YarnDetailsFragment, YarnInput } from '__generated__/graphql';
import { ValueOf } from 'types/core';

export const YarnFormReducer = (
	state: YarnDetailsFragment,
	{ key, value }: { key: keyof YarnInput, value: ValueOf<YarnInput> }
) => {
	switch (key) {
	case 'name':
	case 'shop':
	case 'quantity':
	case 'needlesSize':
	case 'notes':
	case 'imageUrl':
	case 'hostedImage':
	case 'colors':
	case 'composition':
	case 'gauge':
		return {
			...state,
			[key]: value
		};

	default:
		throw new Error(`No reducer available for key = ${key}`);
	}
};
