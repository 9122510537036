import { Div } from 'components/base/Div';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { Link } from 'react-router-dom';
import { translate } from 'services/i18n';
import {
	StyledAdminPageSidebar,
	StyledAdminPageSidebarMenu,
	StyledAdminPageSidebarMenuItem,
	StyledHeader,
	StyledLogoContainer
} from './AdminPageSidebarStyles';

export const AdminPageSidebar = () => {
	const { isMediumScreen } = useScreenWidth();

	const managementNavigation = [
		{ path: '/admin/brands', name: 'Manage brands', sub: false },
		{ path: '/admin/users', name: 'Manage users', sub: false },
	];

	const adminNavigation = [
		{ path: '/admin/stats', name: 'Stats', sub: false },
		{ path: '/admin/logs', name: 'Logs', sub: false },
		{ path: '/admin/messages', name: 'Messages', sub: false }
	];

	return <StyledAdminPageSidebar>
		{!isMediumScreen && (
			<StyledLogoContainer>
				<Link to="/">
					<div>Patterns Collection Manager</div>
				</Link>
			</StyledLogoContainer>
		)}
		<StyledAdminPageSidebarMenu className="ph-1">
			<Div>
				<StyledHeader>{translate({ key: 'Site management' })}</StyledHeader>
				{managementNavigation.map(route => (
					<StyledAdminPageSidebarMenuItem key={route.path} sub={route.sub}>
						<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
					</StyledAdminPageSidebarMenuItem>
				))}
			</Div>
			<Div className="mt">
				<StyledHeader>{translate({ key: 'Console' })}</StyledHeader>
				{adminNavigation.map(route => (
					<StyledAdminPageSidebarMenuItem key={route.path} sub={route.sub}>
						<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
					</StyledAdminPageSidebarMenuItem>
				))}
			</Div>
		</StyledAdminPageSidebarMenu>
	</StyledAdminPageSidebar>;
};
