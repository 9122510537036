import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@mui/material';

interface RadioInputProps {
	options: {
		label: string
		value: string
	}[];
	onChange: (value: any) => void;
	value: string;
	inputName: string;
	inputLabel?: string;
	disabled: boolean;
}

const RadioInput = ({ options, onChange, value, inputName, inputLabel, disabled }: RadioInputProps) => {
	return (
		<FormControl disabled={disabled}>
			{inputLabel && <FormLabel>{inputLabel}</FormLabel>}
			<RadioGroup
				row
				aria-labelledby="demo-radio-buttons-group-label"
				value={value}
				name={inputName}
				onChange={(event, value) => {
					onChange(value);
				}}
			>{options.map(option => <FormControlLabel
					key={option.value}
					value={option.value}
					control={<Radio/>}
					label={option.label}/>)}

			</RadioGroup>
		</FormControl>
	);
};
RadioInput.defaultProps = {
	disabled: false
};
export default RadioInput;
