import { QuantityUnit } from '__generated__/graphql';
import Checkbox from 'components/Inputs/Checkbox';
import MultiSelect from 'components/Inputs/MultiSelect';
import QuantityInput from 'components/Inputs/QuantityInput';
import SearchInput from 'components/Inputs/SearchInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import PatternFormDestinedToSelectionView
	from 'components/PatternForms/PatternFormDestinedToSelectionView/PatternFormDestinedToSelectionView';
import PatternFormFormatSelectionView
	from 'components/PatternForms/PatternFormFormatSelectionView/PatternFormFormatSelectionView';
import useUserFilters from 'customHooks/data/useUserFilters';
import usePatternsSearchEngine from 'customHooks/searchEngine/usePatternsSearchEngine';
import { translate } from 'services/i18n';
import { getId } from 'services/utils';

const PatternsListFiltersView = () => {
	const { brands, categories, tags, fabrics, patternLanguage, boughtIn } = useUserFilters();
	const { currentFilters, updateUrl } = usePatternsSearchEngine();

	return (
		<>
			<GridContainer>
				<GridItem md={4}>
					<GridContainer>
						<GridItem md={12}><SearchInput
							id={'pattern-name'}
							label={translate({ key: 'Pattern name' })}
							value={currentFilters.name || ''}
							onChange={(search) => {
								updateUrl('name', search);
							}}
						/></GridItem>
						<GridItem md={12}>
							<MultiSelect
								label={translate({ key: 'Brand' })}
								onChange={(brands) => {
									updateUrl('brands', brands.join(','));
								}}
								data={(brands || []).map(value => ({ value, label: value }))}
								value={(currentFilters.brands || []).map(value => ({ value, label: value }))}
							/>
							<GridContainer rowSpacing={0}>
								<GridItem>
									<Checkbox
										className="w-100 w-md-50"
										id={getId()}
										checked={!!currentFilters.selfDraftedPattern}
										label={translate({ key: 'Self drafted pattern' })}
										onChange={(checked) => {
											updateUrl('selfDraftedPattern', checked ? 'true' : null);
										}}
									/>
								</GridItem>
								<GridItem>
									<Checkbox
										className="w-100 w-md-50"
										id={getId()}
										label={translate({ key: 'Free pattern' })}
										onChange={checked => updateUrl('isFreePattern', checked ? 'true' : null)}
									/>
								</GridItem>
							</GridContainer>
						</GridItem>
					</GridContainer>
				</GridItem>
				<GridItem md={4}>
					<GridContainer>
						<GridItem md={12}>
							<MultiSelect
								label={translate({ key: 'Categories' })}
								data={categories.map(value => ({ value, label: value }))}
								value={(currentFilters.categories || []).map(value => ({ value, label: value }))}
								onChange={(categories) => {
									updateUrl('categories', categories.join(','));
								}}
							/>
						</GridItem>
						<GridItem md={12}><MultiSelect
							label={translate({ key: 'Tags' })}
							disabled={!tags.length}
							value={(currentFilters.tags || []).map(value => ({ value, label: value }))}
							data={tags.map(value => ({ value, label: value }))}
							onChange={(tags) => {
								updateUrl('tags', tags.join(','));
							}}
						/></GridItem>
					</GridContainer>

				</GridItem>
				<GridItem md={4}>
					<GridContainer>
						<GridItem md={12}>
							<MultiSelect
								disabled={!fabrics.length}
								value={(currentFilters.recommendedFabrics || []).map(value => ({
									value,
									label: translate({ key: value })
								}))}
								data={fabrics}
								label={translate({ key: 'Recommended fabrics' })}
								onChange={(fabrics) => {
									updateUrl('recommendedFabrics', fabrics.join(','));
								}}
							/>
						</GridItem>
						<GridItem md={12}>
							<QuantityInput
								label="Required quantity"
								onChange={({ quantity, unit }) => {
									updateUrl('fabricRequirement', quantity.toString());
									//	handleChange('quantityUnit', unit);
								}
								}
								quantity={currentFilters.fabricRequirement}
								//TODO - adjust
								unit={QuantityUnit.Meters}/>
						</GridItem>
					</GridContainer>
				</GridItem>
			</GridContainer>
			<GridContainer>
				<GridItem md={3}>
					<PatternFormFormatSelectionView
						selectedFormats={currentFilters.availableFormats || []}
						onChange={format => updateUrl('availableFormats', format.join(','))}
					/>
				</GridItem>
				<GridItem md={3}>
					<PatternFormDestinedToSelectionView
						selectedDestinedTo={currentFilters.destinedTo || []}
						onChange={destinedTo => {
							updateUrl('destinedTo', destinedTo.join(''));
						}}
					/>
				</GridItem>
				<GridItem md={3}>
					<MultiSelect
						disabled={!boughtIn.length}
						value={(currentFilters.boughtIn || []).map(value => ({ value, label: value }))}
						data={boughtIn.map(value => ({ value, label: value }))}
						label={translate({ key: 'Bought in' })}
						onChange={(dates) => {
							updateUrl('boughtIn', dates.join(','));
						}}
					/>
				</GridItem>
				<GridItem md={3}>
					<MultiSelect
						disabled={!patternLanguage.length}
						data={patternLanguage.map(value => ({ value, label: value }))}
						value={(currentFilters.patternLanguage || []).map(value => ({
							value,
							label: value
						}))}
						label={translate({ key: 'Language' })}
						onChange={languages => {
							updateUrl('patternLanguage', languages.join(','));
						}}
					/>
				</GridItem>
			</GridContainer>
		</>
	);
};

export default PatternsListFiltersView;
