import { useFabricQuery } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import useNotifications from 'customHooks/utils/useNotifications';
import { useParams } from 'react-router-dom';
import { FabricForm } from 'views/fabrics/FabricForm/FabricForm';

const FabricEditView = () => {
	const { notifyError } = useNotifications();
	const { id } = useParams<'id'>();
	const { loading, data } = useFabricQuery({
		variables: { id },
		onError: err => {
			notifyError(err.message);
		}
	});

	return (
		<FlexContainer column className="align-items-start">
			{loading && <LoadingOverlay/>}
			{data?.fabric && <FabricForm fabric={data.fabric} formTitle={'Fabric edition'}/>}
		</FlexContainer>
	);
};

export default FabricEditView;
