import React, { useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';
import frFlag from 'assets/images/fr_flag.jpg';
import itaFlag from 'assets/images/it_flag.jpg';
import enFlag from 'assets/images/uk_flag.jpg';
import Flag from 'components/Flag/Flag';
import { changeUserLanguage } from 'services/i18n';

const LanguagePicker = () => {
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const languages: { [key: string]: string } = {
		fr: frFlag,
		en: enFlag,
		ita: itaFlag
	};
	const selectedLanguage = window.localStorage.getItem('preferredLanguage') || 'fr';
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};


	return (
		<div className="ml-1">
			<Button
				sx={{ ':hover': { cursor: 'pointer' } }}
				id="language-select-button"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}>
				<Flag src={languages[selectedLanguage]}/>
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
				disableScrollLock
			>
				{Object.entries(languages).map(([key, img]) => {
					return (
						<MenuItem
							sx={{ width: '8em', display: 'flex', justifyContent: 'space-between' }}
							key={key}
							onClick={() => {
								window.localStorage.setItem('preferredLanguage', key);
								changeUserLanguage(key);
								window.location.reload();
							}}
						>
							<span>{key.toUpperCase()}</span>
							<Flag src={img}/>
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
};

export default LanguagePicker;
