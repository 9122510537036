import { ReactNode } from 'react';
import { Breakpoint, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import { translate } from 'services/i18n';

interface ModalContainerProps {
	opened: boolean;
	children: ReactNode;
	onClose: () => void;
	title?: string;
	maxWidth?: Breakpoint;
	onConfirm: () => void;
}

/* TODO : styling */
const ModalContainer = ({ opened, children, onClose, title, maxWidth, onConfirm }: ModalContainerProps) => {
	return (
		<Dialog open={opened} onClose={onClose} fullWidth maxWidth={maxWidth || 'xs'}>
			<DialogTitle>{title}</DialogTitle>
			<DialogContent>
				{children}
			</DialogContent>
			<DialogActions>
				<FlexContainer className={'mb-1'}>
					<Button className="mr-1" onClick={onConfirm} label={translate({ key: 'Confirm' })}/>
					<Button onClick={onClose} label={translate({ key: 'Cancel' })} red/>
				</FlexContainer>
			</DialogActions>
		</Dialog>
	);
};

export default ModalContainer;
