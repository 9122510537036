import { Div } from 'components/base/Div';
import { Link, useLocation } from 'react-router-dom';
import { translate } from 'services/i18n';
import {
	StyledHeader,
	StyledLogoContainer,
	StyledMainPageSidebar,
	StyledMainPageSidebarMenuItem
} from './MainPageSidebarStyles';
import { FlexContainer } from '../../../components/base/FlexContainer';

export const MainPageSidebar = () => {
	const location = useLocation();

	const mainNavigation = [
		{ path: '/patterns', name: 'My patterns', sub: false },
		{ path: '/patterns/new', name: 'Add new pattern', sub: true },
		{ path: '/patterns/sewing?patternType=sewing', name: 'Sewing', sub: true },
		{ path: '/patterns/crochet?patternType=crochet', name: 'Crochet', sub: true },
		{ path: '/patterns/knit?patternType=knit', name: 'Knit', sub: true },
		{ path: '/patterns/other?patternType=other', name: 'Other', sub: true },
		{ path: '/stash', name: 'My stash', sub: false },
		{ path: '/stash/new', name: 'Add new item', sub: true },
		{ path: '/fabrics', name: 'Fabrics', sub: true },
		{ path: '/yarn', name: 'Yarn', sub: true },
		{ path: '/projects', name: 'My projects', sub: false },
		{ path: '/projects/new', name: 'Add new project', sub: true },
		{ path: '/inspirations', name: 'My inspirations', sub: false },
		{ path: '/inspirations/new', name: 'Add new inspiration', sub: true },
	];
	const resourcesNavigation = [
		{ path: '/brands', name: 'Brands', sub: false },
	];

	const footerNavigation = [
		{ path: '/subscription', name: 'Subscription', sub: false },
		{ path: '/feedbacks', name: 'Feedbacks', sub: false },
		{ path: '/about', name: 'About us', sub: false },
		{ path: '/contact', name: 'Contact', sub: false },
		{ path: '/privacy', name: 'Privacy', sub: false },
	];

	return <StyledMainPageSidebar>
		<StyledLogoContainer>
			<Link to="/">
				<div>My Crafting Cloud</div>
			</Link>
		</StyledLogoContainer>
		<FlexContainer column className="ph-1 mr-1">
			<Div>
				<StyledHeader>{translate({ key: 'My library' })}</StyledHeader>
				{mainNavigation.map(route => (
					<StyledMainPageSidebarMenuItem
						key={route.path} sub={!!route.sub}
						active={location.pathname === (route.path.split('?')[0])}>
						<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
					</StyledMainPageSidebarMenuItem>
				))}
			</Div>
			<Div className="mt">
				<StyledHeader>{translate({ key: 'Resources' })}</StyledHeader>
				{resourcesNavigation.map(route => (
					<StyledMainPageSidebarMenuItem
						key={route.path} sub={!!route.sub}
						active={location.pathname === (route.path.split('?')[0])}>
						<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
					</StyledMainPageSidebarMenuItem>
				))}
			</Div>
		</FlexContainer>
		<FlexContainer column alignitems="start">
			{footerNavigation.map(route => (
				<StyledMainPageSidebarMenuItem
					key={route.path} sub={!!route.sub}
					active={location.pathname === (route.path.split('?')[0])}>
					<Link key={route.path} to={route.path}>{translate({ key: route.name })}</Link>
				</StyledMainPageSidebarMenuItem>
			))}
		</FlexContainer>
	</StyledMainPageSidebar>;
};
