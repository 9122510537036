import * as React from 'react';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import { InspirationType } from '__generated__/graphql';
import { Link } from 'react-router-dom';
import RemoteVideo from '../../Inputs/RemoteVideo';

interface ImageListProps {
	images: { name?: string, url: string, navigateLink: string, type: InspirationType }[];
}

const ImageListLayout = ({ images }: ImageListProps) => {
	return (
		<Box sx={{ overflowY: 'auto', height: '100%', width: '100%' }}>
			<ImageList variant="masonry" cols={4} gap={10}>
				{images.map(({ name, url, navigateLink, type }) => (
					<Link key={url} to={navigateLink}>
						<ImageListItem key={url} sx={{ ':hover': { cursor: 'pointer' } }}>
							{type === InspirationType.Image && <img
								className="rounded-container"
								srcSet={`${url}?w=248&fit=crop&auto=format&dpr=2 2x`}
								src={`${url}?w=248&fit=crop&auto=format`}
								alt={name}
								loading="lazy"
							/>}
							{type === InspirationType.Video &&
							<RemoteVideo videoUrl={url} disableClick/>}
							{name &&
							<ImageListItemBar position="bottom" title={name} sx={{ borderRadius: '0 0 0.5em 0.5em' }}/>}
						</ImageListItem>
					</Link>
				))}
			</ImageList>
		</Box>
	);
};

export default ImageListLayout;
