import { FormControl, FormLabel } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import AsyncSelect from 'react-select/async';
import { translate } from 'services/i18n';
import styled from 'styled-components';

export interface AsyncAutoCompleteInputOptionProps {
	imageUrl?: string;
	label: string;
	resourceType?: string;
	value: string;
}

interface AsyncAutoCompleteInputProps {
	id: string;
	fetchOptions: (search: string) => Promise<AsyncAutoCompleteInputOptionProps[]>;
	label: string;
	value: AsyncAutoCompleteInputOptionProps[];
	onChange: (values: AsyncAutoCompleteInputOptionProps[]) => void;
}

const MiniImage = styled.img`
  width: 3em;
  height: 3em;
  border-radius: 3em;
`;

const StyledCustomOption = styled(FlexContainer)`
  padding: 0.5em;
  width: calc(100% - 1em);

  &:hover {
    cursor: pointer;
    background-color: #f7f7f7;
  }
`;
const CustomOption = ({ innerProps, isDisabled, data }: any) => {
	return !isDisabled ? (
		<StyledCustomOption
			ref={innerProps}
			alignitems="flex-start"
			onClick={() => innerProps.onClick()}
			className={'mb-1'}
		>
			{data.imageUrl && <MiniImage src={data.imageUrl} alt={data.label}/>}
			<FlexContainer alignitems="flex-start" column className="pl-1">
				<strong>{data.label}</strong>
				{data.resourceType && <div>{data.resourceType}</div>}
			</FlexContainer>
		</StyledCustomOption>
	) : null;
};

export default function AsyncAutoCompleteInput({
	id,
	label,
	fetchOptions,
	value,
	onChange
}: AsyncAutoCompleteInputProps) {
	const loadOptions = async (inputValue: string) => fetchOptions(inputValue);

	return (
		<FormControl className="w-100">
			<FormLabel>{label}</FormLabel>
			<AsyncSelect
				classNames={{
					control: (state) => state.isFocused ? 'react-select-control-focused react-select-control' : 'react-select-control'
				}}
				classNamePrefix="react-select"
				id={id}
				isMulti
				defaultOptions
				loadOptions={loadOptions}
				components={{
					Option: CustomOption
				}}
				placeholder={`${translate({ key: 'Select' })}...`}
				value={value}
				onChange={(values) => onChange([...values])}
			/>
		</FormControl>
	);
}
