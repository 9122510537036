// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { forwardRef } from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';
import styled from 'styled-components';

interface IInterface {
	rows: number;
	label?: string;
	value: string;
	onChange: (value: string) => void;
	className?: string;
	disabled?: boolean;
	required?: boolean;
}

const StyledTextField = styled(TextField)`
  width: 100%;
  height: fit-content;
  border-color: ${({ theme }) => theme.colors.lightgrey};
  resize: none;

  textarea {
    font-size: 14px;
  }
`;

const TextArea = forwardRef<HTMLInputElement, IInterface>(({
	rows,
	label,
	value,
	onChange,
	className,
	disabled,
	required
}, ref) => {
	return <FormControl className="w-100">
		<FormLabel>{label}</FormLabel>
		<StyledTextField
			ref={ref}
			value={value}
			className={className}
			onChange={e => onChange(e.target.value)}
			multiline
			rows={rows}
			disabled={disabled}
			required={required}
		/>
	</FormControl>;
});

TextArea.displayName = 'TextArea';
TextArea.defaultProps = {
	value: '',
	disabled: false,
	required: false
};
export default TextArea;
