import { QuantityUnit } from '__generated__/graphql';
import LoadingOverlay from 'components/base/LoadingOverlay';
import GaugeInput from 'components/Inputs/GaugeInput';
import HostedImageInput from 'components/Inputs/ImageInput/HostedImageInput';
import MultiSelect from 'components/Inputs/MultiSelect';
import QuantityInput from 'components/Inputs/QuantityInput';
import RangeInput from 'components/Inputs/RangeInput';
import TextEditor from 'components/Inputs/TextEditor';
import TextInput from 'components/Inputs/TextInput';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import { useYarnFormContext } from 'customHooks/formContexts/useYarnFormContext';
import { translate } from 'services/i18n';

const YarnFormContentView = () => {
	const FormContext = useYarnFormContext();
	const { state: yarn, handleChange } = FormContext;
	const { loading, colors, composition, updateUserFilters, yarnWeights } = useUserFilters();

	return yarn ? (
		<>
			{loading && <LoadingOverlay/>}
			<FormLayout
				imgContent={<HostedImageInput
					id="yarn-image"
					image={yarn.hostedImage || null}
					remoteUrl={yarn.imageUrl}
					onChange={(image) => handleChange('hostedImage', image)}
					name={yarn._id || 'image'}
					onRemoteUrlChange={(url) => {
						handleChange('imageUrl', url);
					}}
				/>}
				mainContent={<GridContainer>
					<GridItem xs={12} sm={6}>
						<TextInput
							className="pt-0"
							value={yarn.name || ''}
							onChange={(name) => handleChange('name', name)}
							id="yarn-name"
							label={translate({ key: 'Name' })}
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<QuantityInput
							label="Available quantity"
							onChange={({ quantity, unit }) => {
								handleChange('quantity', quantity);
								handleChange('quantityUnit', unit);
							}
							}
							quantity={yarn.quantity}
							unit={yarn.quantityUnit || QuantityUnit.Meters}/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<RangeInput
							inputLabel={translate({ key: 'Needles size' })}
							onChange={value => handleChange('needlesSize', value)}
							from={yarn.needlesSize.from}
							to={yarn.needlesSize.to}/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<MultiSelect
							onChange={colors => handleChange('colors', colors)}
							data={colors.map(value => ({ value, label: value }))}
							value={yarn.colors.map(value => ({ value, label: value }))}
							creatable
							onCreate={(colors: string) => updateUserFilters({ 'colors': [colors] })}
							label={translate({ key: 'Colors' })}
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<MultiSelect
							onChange={composition => handleChange('composition', composition)}
							data={composition.map(value => ({ value, label: value }))}
							value={yarn.composition.map(value => ({ value, label: value }))}
							creatable
							onCreate={(composition: string) => updateUserFilters({ 'composition': [composition] })}
							label={translate({ key: 'Composition' })}
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<MultiSelect
							onChange={weight => handleChange('weightCategories', weight)}
							data={yarnWeights}
							value={(yarn.weightCategories || []).map(value => ({ value, label: value }))}
							creatable
							onCreate={(weight: string) => updateUserFilters({ 'recommendedYarnWeight': [weight] })}
							label={translate({ key: 'Yarn weight' })}
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<TextInput
							className="pt-0"
							onChange={(shop) => handleChange('shop', shop)}
							id="yarn-shop"
							value={yarn.shop || ''}
							label={translate({ key: 'Shop' })}
						/>
					</GridItem>
					<GridItem xs={12} sm={6}>
						<GaugeInput
							onChange={(gauge) => handleChange('gauge', gauge)}
							gauge={{
								rows: yarn.gauge.rows,
								stitches: yarn.gauge.stitches,
								sampleSize: yarn.gauge.sampleSize
							}}
						/>
					</GridItem>
					<GridItem md={12}>
						<TextEditor
							content={yarn.notes || ''}
							handleChange={notes => handleChange('notes', notes)}
							title={translate({ key: 'Notes' })}/>
					</GridItem>
				</GridContainer>
				}
			>
			</FormLayout>
		</>
	) : <></>;
};

export default YarnFormContentView;
