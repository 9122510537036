import { InputLabel } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import { translate } from 'services/i18n';
import NumberInput from './NumberInput';
import TextInput from './TextInput';

interface GaugeInputProps {
	onChange: (value: { rows: number | null, stitches: number | null, sampleSize: string | null }) => void;
	gauge: {
		rows?: number | null,
		stitches?: number | null,
		sampleSize?: string | null
	};
}

const GaugeInput = ({ onChange, gauge }: GaugeInputProps) => {
	//const { rows, stitches, sampleSize } = gauge;
	return (
		<FlexContainer column>
			<InputLabel>{translate({ key: 'Gauge' })}</InputLabel>
			<FlexContainer>
				<TextInput
					label={translate({ key: 'Sample size' })}
					onChange={value => onChange({ rows: gauge?.rows, stitches: gauge?.stitches, sampleSize: value })}
					id="sample-size"
					value={gauge?.sampleSize || ''}
				/>
				<span>&nbsp;:&nbsp;</span>
				<NumberInput
					onNumberChange={value => onChange({
						rows: gauge?.rows,
						sampleSize: gauge?.sampleSize,
						stitches: value
					})}
					value={gauge?.stitches}
					label={translate({ key: 'Stitches' })}/>
				<span>&nbsp;-&nbsp;</span>
				<NumberInput
					onNumberChange={value => onChange({
						stitches: gauge?.stitches,
						sampleSize: gauge?.sampleSize,
						rows: value
					})}
					value={gauge?.rows}
					label={translate({ key: 'Rows' })}/>
			</FlexContainer>
		</FlexContainer>
	);
};

GaugeInput.defaultProps = {
	gauge: {
		rows: null,
		stitches: null,
		sampleSize: null
	}
};

export default GaugeInput;
