import { useCallback, useEffect, useState } from 'react';
import { faBars, faClose, faPowerOff, faUser, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import LanguagePicker from 'components/LanguagePicker/LanguagePicker';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useFirebaseAuthentication from 'customHooks/services/useFirebaseAuthentication';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { DemonstrationPageSidebar } from 'pages/DemonstrationPage/DemonstrationPageSidebar/DemonstrationPageSidebar';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { StyledHeader, StyledLogoContainer, StyledMobileMenu } from './DemonstrationPageHeaderStyles';

export const DemonstrationPageHeader = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { isMediumScreen, isSuperSmallScreen, isLargeScreen } = useScreenWidth();
	const { user } = useAuthenticatedUser();
	const { signOutOfGoogle } = useFirebaseAuthentication();
	const [menuOpened, setMenuOpened] = useState<boolean>(false);

	const onLogout = useCallback(() => {
		signOutOfGoogle();
		window.location.reload();
	}, [signOutOfGoogle]);

	useEffect(() => {
		setMenuOpened(false);
	}, [location]);

	return <StyledHeader className="justify-end justify-md-between">
		{isMediumScreen && <StyledLogoContainer justifyContent="flex-start">
			<FontAwesomeIcon icon={menuOpened ? faClose : faBars} onClick={() => setMenuOpened(!menuOpened)}/>
			<span className="pl-1" onClick={() => navigate('/')}>
				{translate({ key: 'Patterns Collection Manager' })}
			</span>
		</StyledLogoContainer>}
		{isMediumScreen && (
			<StyledMobileMenu visible={menuOpened}>
				<DemonstrationPageSidebar/>
			</StyledMobileMenu>
		)}
		<FlexContainer className="w-100 w-md-fit-content justify-end">
			ESPACE DE DEMONSTRATION
			{!user && <>
				{isSuperSmallScreen ?
					<Link to="/login">
						<FontAwesomeIcon icon={faUser}/>
					</Link> :
					<Button label={translate({ key: 'Login' })} onClick={() => navigate('/login')}/>
				}
				{!isSuperSmallScreen && isMediumScreen && (
					<Link to="/signin"><FontAwesomeIcon icon={faUserPlus}/></Link>
				)}
				{isLargeScreen && (
					<div className="pl-1">
						<span>
							{translate({ key: 'No account yet ? Sign in' })}
							&nbsp;
						</span>
						<Link to="/signin">{translate({ key: 'here' })}</Link>
					</div>
				)}
			</>
			}
			{user && <>
				<div>
					<Link to="/profile">
						<FontAwesomeIcon icon={faUser}/>
					</Link>
				</div>
				<div onClick={onLogout} className="mr logout">
					{isMediumScreen ?
						<FontAwesomeIcon icon={faPowerOff}/> :
						<span className="pl-1
                        ">{translate({ key: 'Logout' })}</span>}
				</div>
			</>}
			<LanguagePicker/>
		</FlexContainer>
	</StyledHeader>;
};
