import { useState } from 'react';
import { Typography } from '@mui/material';
import {
	namedOperations,
	PatternDetailsFragment,
	PatternType,
	useSaveProjectMutation,
	YarnDetailsFragment
} from '__generated__/graphql';
import ActiveFiltersBox from 'components/base/ActiveFiltersBox';
import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import CheckboxInput from 'components/Inputs/Checkbox';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import usePatternsSearchEngine from 'customHooks/searchEngine/usePatternsSearchEngine';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import CreateProjectModalContentLayout
	from '../../../../components/Layouts/CreateProjectModalContentLayout/CreateProjectModalContentLayout';

const YarnDetailsProjectsView = ({ yarn }: { yarn: YarnDetailsFragment }) => {
	const navigate = useNavigate();
	const { notifyError } = useNotifications();
	const [page, setPage] = useState(1);
	const [filterOnType, setFilterOnType] = useState(!!yarn.weightCategories.length);
	const [filterOnQuantity, setFilterOnQuantity] = useState(!!yarn.quantity);
	const { data, loading } = usePatternsSearchEngine({
		limit: 6, overrideFilters: {
			page,
			recommendedYarnWeight: filterOnType ? yarn.weightCategories : null,
			patternType: [PatternType.Sewing, PatternType.Crochet],
			//TODO - adjust quantity
			fabricRequirement: filterOnQuantity ? yarn.quantity : null
		}
	});
	const [saveProject, { loading: savingProject }] = useSaveProjectMutation({
		onCompleted: ({ saveProject }) => {
			navigate(`/projects/${saveProject._id}`);
		},
		refetchQueries: [namedOperations.Query.getProjectsList],
		onError: error => notifyError(error.message)
	});

	return <FlexContainer column>
		{(loading || savingProject) && <LoadingOverlay/>}
		<Divider
			className="mb-1"
			label={<Typography variant="subtitle1">{translate({
				key: 'Projects you can do with that yarn',
				data: { count: data?.count || 0 }
			})}</Typography>}/>
		<ActiveFiltersBox>
			<CheckboxInput
				checked={filterOnType}
				disabled={!yarn.weightCategories.length}
				onChange={(checked) => setFilterOnType(checked)}
				label={translate({ key: 'Composition' })}
				id="pattern-yarn-composition"/>
			<CheckboxInput
				checked={filterOnQuantity}
				disabled={!yarn.quantity}
				onChange={(checked) => setFilterOnQuantity(checked)}
				label={translate({ key: 'Quantity' })}
				id="pattern-yarn-quantity"/>
		</ActiveFiltersBox>
		{!!data?.patterns.length && (
			<ListLayout
				className="pt-1"
				items={data?.patterns || []}
				component={(item: PatternDetailsFragment) => <ListGridItem
					item={{ name: item.name, imageUrl: item.imageUrl }}
					projectCreatable
					initialProjectName={`${item.name} / ${yarn.name}`}
					navigateLink={`/patterns/${item._id}`}
					deletable={false}
					modalTitle="Project creation"
					modalContent={<CreateProjectModalContentLayout
						headerText={'Create a project with these pattern and yarn'}
						patternImage={{ imageUrl: item.imageUrl, name: item.name }}
						stashImage={{ imageUrl: yarn.imageUrl, name: yarn.name }}/>
					}
					onModalConfirm={(name: string) => saveProject({
						variables: {
							input: {
								patternIds: [item._id],
								stashIds: [yarn.stashId],
								name
							}
						}
					})}
				/>}
				currentPage={1}
				total={data?.count}
				limit={12}
				onPageChange={page => setPage(page)}
			/>
		)}
		{!data?.patterns.length && `${translate({
			key: 'We found no patterns in your collection for that type and quantity of yarn'
		})
		}.`}
	</FlexContainer>;
};

export default YarnDetailsProjectsView;
