import { KeyboardEventHandler, ReactElement, ReactNode, useState } from 'react';
import { FormControl, FormLabel } from '@mui/material';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

interface Option {
	label: string;
	value: string;
}

type MultiSelectInputProps = {
	data: Option[];
	label?: string | ReactNode | ReactElement;
	onCreate?: (value: string) => void;
	invalid?: boolean;
	required?: boolean;
	disabled?: boolean;
	creatable?: boolean;
	clearable?: boolean;
	searchable?: boolean;
	placeholder?: string;
	value: Option[];
	onChange: (value: string[]) => void;
}

const MultiSelectInput = ({
	value,
	data,
	onChange,
	label,
	invalid,
	required,
	disabled,
	searchable,
	clearable,
	creatable,
	onCreate,
	placeholder,
}: MultiSelectInputProps) => {
	const [search, setSearch] = useState('');
	const handleKeyDown: KeyboardEventHandler = (event) => {
		if (!search) return;
		switch (event.key) {
		case 'Enter': {
			event.preventDefault();
			const existingValue = data.find(({ value }) => value === search);
			if (existingValue) {
				onChange((value || []).concat(existingValue).map(({ value }: { value: string }) => value));
			} else {
				if (creatable) {
					onCreate(search);
					onChange((value || []).map(({ value }: { value: string }) => value).concat(search));
				}
			}
			setSearch('');
		}
		}
	};

	return (
		<FormControl className="w-100" error={invalid}>
			<FormLabel>{label}</FormLabel>
			{creatable ? <CreatableSelect
				classNames={{
					control: (state) => state.isFocused ? 'react-select-control-focused react-select-control' : 'react-select-control'
				}}
				classNamePrefix="react-select"
				isDisabled={disabled}
				closeMenuOnSelect
				required={required}
				className="w-100"
				inputValue={search}
				isClearable={clearable}
				isSearchable={searchable}
				isMulti
				onChange={(newValue) => {
					onChange(newValue.map(({ value }: { value: string }) => value));
				}}
				options={data}
				onInputChange={(searchValue) => setSearch(searchValue)}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
				value={value}
				onCreateOption={(newOption: string) => {
					onCreate(newOption);
					onChange(value.map(({ value }) => value).concat(newOption));
				}}
			/> : <Select
				className="w-100"
				classNamePrefix="react-select"
				classNames={{
					control: (state) => state.isFocused ? 'react-select-control-focused react-select-control' : 'react-select-control'
				}}
				required={required}
				closeMenuOnSelect
				inputValue={search}
				isDisabled={disabled}
				isClearable={clearable}
				isSearchable={searchable}
				onChange={(newValue) => {
					onChange(newValue.map(({ value }: { value: string }) => value));
				}}
				isMulti
				options={data}
				onInputChange={(searchValue) => setSearch(searchValue)}
				onKeyDown={handleKeyDown}
				placeholder={placeholder}
				value={value}
			/>}

		</FormControl>
	);
};

MultiSelectInput.defaultProps = {
	clearable: true,
	creatable: false,
	searchable: true,
};
export default MultiSelectInput;
