import { YarnDetailsFragment } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RemoteImage from 'components/base/RemoteImage';
import { translate } from 'services/i18n';

const DeleteYarnModal = ({ yarn }: { yarn: YarnDetailsFragment }) => {
	return (
		<FlexContainer>
			<div style={{ alignSelf: 'flex-start' }}>
				{`${translate({ key: 'Are you sure you want to delete that yarn' })} ?`}
			</div>
			{!!yarn.imageUrl && <div className="w-50">
				<RemoteImage src={yarn.imageUrl} alt={yarn.name}/>
			</div>}
		</FlexContainer>
	);
};

export default DeleteYarnModal;
