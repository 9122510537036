import 'firebase/compat/auth';
import { namedOperations, useLoginWithProviderMutation } from '__generated__/graphql';
import { AuthCredential, GoogleAuthProvider, OAuthCredential, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useLocation, useNavigate } from 'react-router-dom';
import StyledFirebaseAuth from './StyledFirebaseAuth';

const { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_APP_NAME, REACT_APP_FIREBASE_AUTH_DOMAIN } = process.env;

const config = {
	apiKey: REACT_APP_FIREBASE_API_KEY,
	authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
	appName: REACT_APP_FIREBASE_APP_NAME
};
firebase.initializeApp(config);

const FirebaseSigninView = () => {
	const navigate = useNavigate();
	const { state } = useLocation() as { state: { from: { pathname: string, search: string } } };

	const [loginWithProvider] = useLoginWithProviderMutation({
		onCompleted: async () => {
			navigate(state?.from ? `${state.from.pathname}${state.from.search}` : '/', { replace: true });
		},
		refetchQueries: [namedOperations.Query.getProfile, namedOperations.Query.getAuthenticatedUser]
	});
	const uiConfig = {
		signInFlow: 'popup',
		signInOptions: [
			{
				provider: GoogleAuthProvider.PROVIDER_ID,
				customParameters: {
					prompt: 'select_account'
				}
			}
		],
		callbacks: {
			signInSuccessWithAuthResult: (authResult: { user: User, credential: AuthCredential }) => {
				const credential = authResult.credential.toJSON() as OAuthCredential;
				loginWithProvider({
					variables: {
						token: credential.idToken,
						email: authResult.user.email
					}
				});
				return true;
			}
		}
	};
	return <StyledFirebaseAuth className="w-100" uiConfig={uiConfig} firebaseAuth={firebase.auth()}/>;
};

export default FirebaseSigninView;


