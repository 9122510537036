import { ReactNode, useMemo, useState } from 'react';
import { faEdit, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Tab, Tabs, Typography } from '@mui/material';
import { FlexContainer } from 'components/base/FlexContainer';
import { Button } from 'components/Button/Button';
import ModalContainer from 'components/ModalContainer/ModalContainer';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import GridContainer from '../Grid/GridContainer';
import GridItem from '../Grid/GridItem';

interface DetailsLayoutProps {
	imgContent: ReactNode;
	mainContent: ReactNode;
	relatedEntitiesContent: ReactNode;
	relatedEntitiesTitle?: string;
	notes: string;
	children?: ReactNode;
	className?: string;
	itemName: string | ReactNode;
	editLink: string;
	onDeleteConfirm: () => void;
	deleteModalContent: ReactNode;
	deleteModalTitle: string;
}

const getTabValueFromHash = (hash: string) => {
	switch (hash) {
	case '#2':
		return 1;
	case '#3':
		return 2;
	case '#1':
	default:
		return 0;
	}
};
const DetailsLayout = ({
	imgContent,
	children,
	mainContent,
	notes,
	className,
	itemName,
	relatedEntitiesContent,
	relatedEntitiesTitle,
	editLink,
	onDeleteConfirm,
	deleteModalContent,
	deleteModalTitle
}: DetailsLayoutProps) => {
	const location = useLocation();
	const navigate = useNavigate();
	const [deleteModalOpened, setDeleteModalOpened] = useState(false);
	const selectedTab = useMemo(() => getTabValueFromHash(location.hash), [location.hash]);

	return (
		<FlexContainer column className={`w-100 ${className}`}>
			<ModalContainer
				title={deleteModalTitle} opened={deleteModalOpened}
				onClose={() => setDeleteModalOpened(false)}
				onConfirm={onDeleteConfirm}
			>
				{deleteModalContent}
			</ModalContainer>
			<GridContainer className="h-100 pv-1">
				<GridItem xs={12} md={3}>
					{imgContent}
				</GridItem>
				<GridItem xs={12} md={9} className="ph-1">
					<FlexContainer justifyContent="space-between">
						{typeof itemName === 'string' ?
							<Typography variant="h5" className="w-100">{itemName}</Typography> : itemName}
						<FlexContainer className="w-100" justifyContent="end">
							<Link to={editLink}>
								<Button
									disableIfDemo
									label={translate({ key: 'Edit' })}
									onClick={() => navigate(editLink)}
									icon={<FontAwesomeIcon icon={faEdit}/>}
								/>
							</Link>
							<Button
								disableIfDemo
								sx={{
									marginLeft: '1em'
								}}
								label={translate({ key: 'Delete' })}
								onClick={() => setDeleteModalOpened(true)}
								red
								icon={<FontAwesomeIcon icon={faTrashCan}/>}
							/>
						</FlexContainer>
					</FlexContainer>
					<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
						<Tabs
							value={getTabValueFromHash(location.hash)}
							onChange={(_, tab) => navigate(`#${tab + 1}`)}>
							<Tab label={translate({ key: 'Description' })}/>
							<Tab label={translate({ key: 'Notes' })}/>
							<Tab label={relatedEntitiesTitle || translate({ key: 'Related projects default' })}/>
						</Tabs>
					</Box>
					<div
						role="tabpanel"
						hidden={selectedTab !== 0}
						id="main-panel"
						aria-labelledby={'simple-tab-0'}
						className="ph-1 pv-1"
					>
						{selectedTab === 0 && mainContent}
					</div>
					<div
						role="tabpanel"
						hidden={selectedTab !== 1}
						className="ph-1 pv-1"
						id="notes-panel"
						aria-labelledby={'simple-tab-1'}>
						{selectedTab === 1 && <>
							{notes.length ? < div dangerouslySetInnerHTML={{ __html: notes }}/> : '--'}
						</>}
					</div>
					<div
						role="tabpanel"
						hidden={selectedTab !== 2}
						className="ph-1 pv-1"
						id="related-entities-panel"
						aria-labelledby={'simple-tab-2'}>
						{selectedTab === 2 && relatedEntitiesContent}
					</div>
				</GridItem>
			</GridContainer>
			<FlexContainer className="pv">{children}</FlexContainer>
		</FlexContainer>
	);
};

DetailsLayout.defaultProps = {
	relatedProjects: []
};
export default DetailsLayout;
