import { FC } from 'react';
import BrandsListView from 'views/brands/BrandsListView/BrandsListView';
import ContactPageView from 'views/contact/ContactPageView';
import FabricDetailsView from 'views/fabrics/FabricDetailsView/FabricDetailsView';
import FabricEditView from 'views/fabrics/FabricEditView/FabricEditView';
import FabricsListView from 'views/fabrics/FabricsListView/FabricsListView';
import FeedbacksListView from 'views/feedbacks/FeedbacksListView';
import HomepageView from 'views/home/HomepageView';
import InspirationCreationView from 'views/inspirations/InspirationCreationView/InspirationCreationView';
import InspirationDetailsView from 'views/inspirations/InspirationDetailsView/InspirationDetailsView';
import InspirationEditView from 'views/inspirations/InspirationEditView/InspirationEditView';
import InspirationsListView from 'views/inspirations/InspirationsListView/InspirationsListView';
import ForgetPasswordView from 'views/login/ForgetPasswordView';
import LoginView from 'views/login/LoginView';
import ResetPasswordView from 'views/login/ResetPasswordView';
import SignInView from 'views/login/SignInView';
import PatternCreationView from 'views/patterns/PatternCreationView/PatternCreationView';
import PatternDetailsView from 'views/patterns/PatternDetailsView/PatternDetailsView';
import PatternEditView from 'views/patterns/PatternEditView/PatternEditView';
import PatternsListView from 'views/patterns/PatternsListView/PatternsListView';
import ProfileDetailsView from 'views/profiles/ProfileDetailsView';
import ProjectCreationView from 'views/projects/ProjectCreationView/ProjectCreationView';
import ProjectDetailsView from 'views/projects/ProjectDetailsView/ProjectDetailsView';
import ProjectEditView from 'views/projects/ProjectEditView/ProjectEditView';
import ProjectsListView from 'views/projects/ProjectsListView/ProjectsListView';
import StashItemCreationView from 'views/stash/StashItemCreationView/StashItemCreationView';
import StashListView from 'views/stash/StashListView';
import SubscriptionView from 'views/subscription/SubscriptionView';
import YarnDetailsView from 'views/yarns/YarnDetailsView/YarnDetailsView';
import YarnEditView from 'views/yarns/YarnEditView/YarnEditView';
import YarnsListView from 'views/yarns/YarnsListView/YarnsListView';
import PrivacyPolicyView from '../views/privacy/PrivacyPolicyView';

export type RouteProps = {
	path: string,
	name?: string,
	Component: FC,
	needAuthentication: boolean,
	breadcrumbs?: { name: string, link: string }[]
}

const patternsRoutes: RouteProps[] = [
	{ path: 'patterns', name: 'My patterns', Component: PatternsListView, needAuthentication: true },
	{ path: 'patterns/sewing', name: 'Sewing patterns', Component: PatternsListView, needAuthentication: true },
	{ path: 'patterns/knit', name: 'Knit patterns', Component: PatternsListView, needAuthentication: true },
	{ path: 'patterns/crochet', name: 'Crochet patterns', Component: PatternsListView, needAuthentication: true },
	{ path: 'patterns/other', name: 'Other patterns', Component: PatternsListView, needAuthentication: true },
	{
		path: 'patterns/new',
		name: 'New pattern',
		Component: PatternCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }]
	},
	{
		path: 'patterns/:id',
		name: 'Pattern details',
		Component: PatternDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }]
	},
	{
		path: 'patterns/:id/edit',
		name: 'Pattern edition',
		Component: PatternEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My patterns', link: '/patterns' }]
	},
];

const stashRoutes = [
	{ path: 'stash', name: 'My stash', Component: StashListView, needAuthentication: true },
	{ path: 'stash/new', name: 'New item', Component: StashItemCreationView, needAuthentication: true },
	{ path: 'fabrics', name: 'Fabrics', Component: FabricsListView, needAuthentication: true },
	{
		path: 'fabrics/:id', name: 'Fabric details', Component: FabricDetailsView, needAuthentication: true,
		breadcrumbs: [{ name: 'My fabrics', link: '/fabrics' }]
	},
	{
		path: 'fabrics/:id/edit', name: 'Fabric edition', Component: FabricEditView, needAuthentication: true,
		breadcrumbs: [{ name: 'My fabrics', link: '/fabrics' }]
	},
	{ path: 'yarn', name: 'Yarn', Component: YarnsListView, needAuthentication: true },
	{
		path: 'yarn/:id/edit', name: 'Yarn edition', Component: YarnEditView, needAuthentication: true,
		breadcrumbs: [{ name: 'My yarn', link: '/yarn' }]
	},
	{
		path: 'yarn/:id', name: 'Yarn details', Component: YarnDetailsView, needAuthentication: true,
		breadcrumbs: [{ name: 'My yarn', link: '/yarn' }]
	},
];

const inspirationsRoutes = [
	{ path: 'inspirations', name: 'Inspirations', Component: InspirationsListView, needAuthentication: true },
	{
		path: 'inspirations/new', name: 'New inspiration', Component: InspirationCreationView, needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }]
	},
	{
		path: 'inspirations/:id',
		name: 'Inspiration details',
		Component: InspirationDetailsView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }]
	},
	{
		path: 'inspirations/:id/edit',
		name: 'Inspiration edition',
		Component: InspirationEditView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My inspirations', link: '/inspirations' }]
	},
];

const projectsRoutes = [
	{ path: 'projects', name: 'My projects', Component: ProjectsListView, needAuthentication: true },
	{
		path: 'projects/new',
		name: 'New project',
		Component: ProjectCreationView,
		needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }]
	},
	{
		path: 'projects/:id', name: 'Project details', Component: ProjectDetailsView, needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }]
	},
	{
		path: 'projects/:id/edit', name: 'Edit project', Component: ProjectEditView, needAuthentication: true,
		breadcrumbs: [{ name: 'My projects', link: '/projects' }]
	},
];

const subscriptionRoutes = [
	{ path: 'subscription', name: 'Subscription', Component: SubscriptionView, needAuthentication: false },
];

const resourcesRoutes = [
	{ path: 'brands', name: 'Brands', Component: BrandsListView, needAuthentication: false },
];

const feedbacksRoutes = [
	{ path: 'feedbacks', name: 'Feedbacks', Component: FeedbacksListView, needAuthentication: false },
];
const profileRoutes = [
	{ path: 'profile', name: 'Profile', Component: ProfileDetailsView, needAuthentication: true },
];
const loginRoutes: RouteProps[] = [
	{ path: 'login', name: 'Login', Component: LoginView, needAuthentication: false },
	{ path: 'signin', name: 'Signin', Component: SignInView, needAuthentication: false },
	{ path: 'forgetPassword', name: 'Forget password', Component: ForgetPasswordView, needAuthentication: false },
	{ path: 'resetPassword', name: 'Reset password', Component: ResetPasswordView, needAuthentication: false },
];
const otherRoutes = [
	{ path: 'contact', name: 'Contact', Component: ContactPageView, needAuthentication: false },
	{
		path: 'privacy',
		name: 'Privacy',
		Component: PrivacyPolicyView,
		needAuthentication: false
	},
];

const routes: RouteProps[] = [
	{ path: '/', Component: HomepageView, needAuthentication: false, breadcrumbs: [] },
	...patternsRoutes,
	...stashRoutes,
	...projectsRoutes,
	...inspirationsRoutes,
	...resourcesRoutes,
	...feedbacksRoutes,
	...profileRoutes,
	...loginRoutes,
	...subscriptionRoutes,
	...otherRoutes
];

export default routes;
