import { useState } from 'react';
import { faImages } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { FlexContainer } from './FlexContainer';

export const StyledPlaceholder = styled(FlexContainer)`
  width: 100%;
  height: 100%;
  aspect-ratio: 0.75;
  align-items: center;
  align-content: center;
  background-color: lightgrey;
  background-image: linear-gradient(45deg, #9de6f2 0%, #ebfff5 100%);
  border-radius: 0.5em;
  color: ${({ theme }) => theme.colors.darkgrey};

  span {
    text-align: center;
  }

  svg {
    width: 5em;
    height: 5em;
    margin-bottom: 2em;

  }

  @media screen and (max-width: 1200px) {
    font-size: 0.8em;
    svg {
      width: 3em;
      height: 3em;
      margin-bottom: 1em;
    }
  }
`;

interface RemoteImageProps {
	src: string | null;
	alt: string;
}

const StyledImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 0.5em;
`;

const RemoteImage = ({ src, alt }: RemoteImageProps) => {
	const [isBrokenImage, setIsBrokenImage] = useState(false);
	return src && !isBrokenImage ? <StyledImage src={src} alt={alt} onError={() => {
		setIsBrokenImage(true);
	}}/> :
		<StyledPlaceholder column>
			<FontAwesomeIcon icon={faImages}/>
		</StyledPlaceholder>;
};


export default RemoteImage;
