import { useState } from 'react';
import {
	FabricDetailsFragment,
	InspirationDetailsFragment,
	namedOperations,
	PatternDetailsFragment,
	ProjectDetailsFragment,
	useSaveProjectMutation,
	YarnDetailsFragment
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import ListGridItem from 'components/Layouts/ListLayout/ListGridItem';
import ListLayout from 'components/Layouts/ListLayout/ListLayout';
import ProjectImage from 'components/ProjectImage/ProjectImage';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import RemoteImage from '../base/RemoteImage';
import { Button } from '../Button/Button';
import TextInput from '../Inputs/TextInput';
import ModalContainer from '../ModalContainer/ModalContainer';

interface ActiveProjectsListViewProps {
	projects: ProjectDetailsFragment[],
	total: number,
	page: number,
	onPageChange: (value: number) => void
	data: {
		type: 'pattern' | 'inspiration' | 'fabric' | 'yarn'
		pattern?: PatternDetailsFragment
		inspiration?: InspirationDetailsFragment
		fabric?: FabricDetailsFragment
		yarn?: YarnDetailsFragment
	}
}

const ActiveProjectsListView = ({ projects, total, page, onPageChange, data }: ActiveProjectsListViewProps) => {
	const navigate = useNavigate();
	const item = data.pattern || data.inspiration || data.yarn || data.fabric;
	const [modalOpened, setModalOpened] = useState(false);
	const [projectName, setProjectName] = useState(item.name);
	const [saveProject] = useSaveProjectMutation({
		onCompleted: ({ saveProject }) => {
			navigate(`/projects/${saveProject._id}`);
		},
		refetchQueries: [namedOperations.Query.getProjectsList]
	});
	return (
		<FlexContainer column>
			<ModalContainer
				opened={modalOpened}
				onClose={() => setModalOpened(false)}
				onConfirm={() => {
					saveProject({
						variables: {
							input: {
								name: projectName,
								...data.pattern ? { patternIds: [data.pattern._id] } : {},
								...data.inspiration ? { inspirationIds: [data.inspiration._id] } : {},
								...data.yarn ? { stashIds: [data.yarn.stashId] } : {},
								...data.fabric ? { stashIds: [data.fabric.stashId] } : {},
							}
						}
					});
				}}
				title={translate({ key: 'Create project' })}>
				<FlexContainer column>
					<TextInput
						onChange={value => setProjectName(value)}
						value={projectName}
						id="project-name"
						label={translate({ key: 'Project name' })}/>
					{(item?.imageUrl) && <FlexContainer className={'w-100'}>
						<RemoteImage src={item.imageUrl} alt={item.name}/>
					</FlexContainer>}
				</FlexContainer>
			</ModalContainer>
			<Button
				sx={{ alignSelf: 'flex-start', marginBottom: '1em' }}
				label={translate({ key: 'Create project' })}
				onClick={() => setModalOpened(true)}
			/>
			<ListLayout
				items={projects || []}
				component={project => <ListGridItem
					deletable={false}
					navigateLink={`/projects/${project._id}`}
					item={project}
					overrideComponent={<ProjectImage project={project}/>}
				/>}
				limit={6}
				currentPage={page}
				onPageChange={onPageChange}
				total={total}
			/>
		</FlexContainer>
	);
};

export default ActiveProjectsListView;
