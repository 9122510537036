import { TextField } from '@mui/material';
import { translate } from 'services/i18n';
import GridContainer from '../Layouts/Grid/GridContainer';
import GridItem from '../Layouts/Grid/GridItem';

interface LinkInputProps {
	onChange: (e: { url: string, name: string }) => void;
	url: string;
	name: string;
}

const LinkInput = ({ onChange, name, url }: LinkInputProps) => {
	return (
		<GridContainer>
			<GridItem xs={12} md={6} className="w-100">
				<TextField
					sx={{
						label: {
							fontWeight: 'normal'
						}
					}}
					className="w-100"
					label={translate({ key: 'Link name' })}
					value={name || ''}
					onChange={(e) => onChange({ name: e.target.value, url })}/>
			</GridItem>
			<GridItem xs={12} md={6}>
				<TextField
					className="w-100"
					sx={{
						label: {
							fontWeight: 'normal'
						}
					}}
					label={translate({ key: 'Link url' })}
					value={url || ''}
					onChange={e => onChange({ name, url: e.target.value })}/>
			</GridItem>
		</GridContainer>
	);
};

export default LinkInput;
