import { PatternDetailsFragment } from '__generated__/graphql';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { translate } from 'services/i18n';

const PatternDetailsDescriptionView = ({ pattern }: { pattern: PatternDetailsFragment }) => {
	return <GridContainer>
		<FieldDescriptionBox
			label={translate({ key: 'Categories' })}
			content={pattern.categories?.length ?
				pattern.categories.map((category: string) => category.toUpperCase()).join(' - ') : '--'
			}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Recommended fabrics' })}
			content={pattern.recommendedFabrics?.length ?
				pattern.recommendedFabrics.map((fabric: string) => translate({ key: fabric }).toUpperCase()).join(' - ') : '--'
			}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Destined to' })}
			content={pattern.destinedTo.map(key => translate({ key })).join(' - ')}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Quantity required' })}
			content={pattern.fabricRequirement && pattern.fabricQuantityUnit ?
				(`${pattern.fabricRequirement} ${translate({ key: pattern.fabricQuantityUnit })}`) : '--'}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Available formats' })}
			content={pattern.availableFormats?.length ?
				pattern.availableFormats.map((key: string) => translate({ key })).join(' - ') :
				'--'
			}/>
		<FieldDescriptionBox
			label={translate({ key: 'Videos' })}
			content={<div>
				{(pattern.relevantVideos || []).map(({
					url,
					name
				}: { url: string, name: string }, index: number) => (
					<div key={index}>
						<a rel="noreferrer noopener" href={url} target="_blank">{name || url}</a>
					</div>
				))}
				{(!pattern.relevantVideos || !pattern.relevantVideos.length) && '--'}
			</div>}
		/>
		<FieldDescriptionBox
			label={translate({ key: 'Files' })}
			content={<div>
				{/*{(pattern.files || []).map(({
					url,
					name
				}: { url: string, name: string }, index: number) => (
					<div key={index}>
						<a rel="noreferrer noopener" href={url} target="_blank">{name || url}</a>
					</div>
				))}
				{(!pattern.files || !pattern.files.length) && '--'}*/}
			</div>}
		/>
		<FieldDescriptionBox label={translate({ key: 'Language' })} content={pattern.patternLanguage}/>
		<FieldDescriptionBox label={translate({ key: 'Bought in' })} content={pattern.boughtIn}/>
	</GridContainer>;
};

export default PatternDetailsDescriptionView;
