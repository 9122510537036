import styled from 'styled-components';
import { FlexContainer } from '../../components/base/FlexContainer';

export const StyledMainPage = styled.div`
  height: 100vh;
  display: flex;

  h1 {
    margin: 0;
    padding: 0 0 0.67em 0;
    font-size: 20px;
    font-weight: bold;
  }
`;

export const StyledPageContainer = styled.div`
  height: fit-content;
  width: 100%;
  margin-left: 20em;
  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }
`;

export const StyledRoutesContainer = styled(FlexContainer)`
  margin-top: 6em;
`;

export const StyledBreadcrumbsContainer = styled(FlexContainer)`
  padding-left: 2em;
  width: 90%;
  justify-content: flex-start;
  align-self: flex-start;
  margin-bottom: 1em;
`;

export const StyledContent = styled.div`
  padding: 1em 2em;
  margin: 0 2em 2em 2em;
  background: ${({ theme }) => theme.colors.white};
  border-radius: 0.5em;
  min-height: 60vh;
  height: calc(100% - 12em);
  width: calc(100% - 8em);
`;
