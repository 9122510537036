import { ReactNode, useCallback, useState } from 'react';
import { FormControl, FormLabel, TextField } from '@mui/material';

export interface TextInputProps {
	value?: string
	label?: string | ReactNode
	onChange: (value: string) => void
	onSubmit?: () => void
	invalid?: boolean
	required?: boolean
	disabled?: boolean
	placeholder?: string
	className?: string
	validate?: (value?: string) => boolean
	errorMessage?: string
	max?: number,
	id: string
	icon?: ReactNode
}

const TextInput = ({
	value,
	label,
	onChange,
	invalid,
	required,
	disabled,
	placeholder,
	className,
	validate,
	errorMessage,
	max,
	id,

}: TextInputProps) => {
	const [error, setError] = useState<string | null>(invalid && errorMessage ? errorMessage : null);
	const validateOnBlur = useCallback(() => {
		if (validate && errorMessage && !validate(value)) {
			setError(errorMessage || 'invalid');
		}
	}, [validate, errorMessage, value]);

	return <FormControl error={invalid || !!error} className={`w-100 ${className}`}>
		<FormLabel>{label}</FormLabel>
		<TextField
			id={id}
			className={`${className} w-100`}
			value={value}
			required={required}
			error={invalid || !!error}
			onChange={(e) => {
				setError(null);
				if (!max || e.target.value.length <= max) {
					onChange(e.target.value);
				}
			}}
			placeholder={placeholder}
			disabled={disabled}
			onBlur={validateOnBlur}
			helperText={error}
		/>
	</FormControl>;
};

export default TextInput;
