import { useCallback } from 'react';
import {
	FileInput,
	namedOperations,
	PatternDetailsFragment,
	PatternInput,
	PatternType,
	useSavePatternMutation
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RadioInput from 'components/Inputs/RadioInput';
import FormContainerLayout from 'components/Layouts/FormContainerLayout/FormContainerLayout';
import { FormProvider } from 'customHooks/formContexts/usePatternFormContext';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { ValueOf } from 'types/core';
import { useImmerReducer } from 'use-immer';
import PatternFormContent from './PatternFormContent';
import { PatternFormReducer } from './PatternFormReducer';

export const PatternForm = ({ pattern, onSavePattern, formTitle }: {
	pattern: PatternDetailsFragment & { hostedImage?: FileInput },
	onSavePattern?: (pattern: PatternDetailsFragment) => void,
	formTitle: string
}) => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const [state, dispatch] = useImmerReducer(PatternFormReducer, pattern);
	const [savePattern, { loading }] = useSavePatternMutation({
		refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.pattern],
		onError: err => {
			notifyError(err.message);
		},
		onCompleted: ({ savePattern: pattern }) => {
			if (pattern) {
				navigate(`/patterns/${pattern._id}`);
			} else {
				notifyError('Some error occured');
			}
		}
	});

	const handleChange = useCallback(
		(key: keyof PatternInput, value: ValueOf<PatternInput>) => {
			dispatch({ key, value });
		}, [dispatch]
	);

	const onSubmit = useCallback(async () => {
		const { relevantVideos, files, currentProjectsIds, __typename, ...submittedPattern } = state;
		if (onSavePattern) {
			onSavePattern(submittedPattern as PatternDetailsFragment);
		} else {
			await savePattern({
				variables: {
					input: {
						...submittedPattern,
						relevantVideos: relevantVideos.filter(({ url }) => url),
						files: files.filter(({ name, file: { url } }) => name && url)
					}
				}
			});
		}
	}, [savePattern, state, onSavePattern]);
	return (
		<FormContainerLayout
			formTitle={formTitle}
			onSave={onSubmit}
			loading={loading}
			isValid={!!state.name}
			disabledMessage={translate({ key: 'You must give a name to the pattern' })}
		>
			<FlexContainer className="w-100">
				<RadioInput
					options={[
						{ value: PatternType.Sewing, label: translate({ key: 'Sewing' }) },
						{ value: PatternType.Crochet, label: translate({ key: 'Crochet' }) },
						{ value: PatternType.Knit, label: translate({ key: 'Knit' }) },
						{ value: PatternType.Other, label: translate({ key: 'Other' }) },
					]}
					onChange={patternType => handleChange('patternType', patternType)}
					value={state.patternType}
					inputName="pattern-type-selector"/>
			</FlexContainer>
			{state && <FormProvider value={{ state, handleChange }}>
				<PatternFormContent/>
			</FormProvider>}
		</FormContainerLayout>
	);
};

PatternForm.defaultProps = {
	formTitle: 'New pattern',
	pattern: {
		name: '',
		availableFormats: [],
		selfDraftedPattern: false,
		tags: [],
		brand: null,
		categories: [],
		difficulty: 1,
		destinedTo: [],
		recommendedFabrics: [],
		isFreePattern: false,
		patternLanguage: null,
		relevantVideos: [{ name: '', link: '' }],
		files: [{ name: '', file: { url: '' } }],
		fabricRequirement: null,
		hostedImage: null,
		imageUrl: null,
		fittingAdjustmentRequired: false,
		projectorAdjustmentRequired: false,
		patternType: PatternType.Sewing,
		needlesSize: [],
		recommendedYarnWeight: [],
		gauge: {
			rows: null,
			stitches: null,
			sampleSize: null
		}
	}
};
