import imageCompression from 'browser-image-compression';

const UseImageCompressor = () => {
	const options = {
		maxSizeMB: 0.05,
		maxWidthOrHeight: 500
	};

	return { compressImage : async (file: File) => imageCompression(file, options) };
};

export default UseImageCompressor;
