import { ReactNode } from 'react';
import { translate } from 'services/i18n';
import styled from 'styled-components';
import { FlexContainer } from './FlexContainer';

const StyledContainer = styled(FlexContainer)`
  background-color: #f7f7f7;
  border-radius: 0.5em;
  justify-content: flex-start;
  padding-left: 1em;
  margin-bottom: 1em;
  width: calc(100% - 1em);
`;

const ActiveFiltersBox = ({ children }: { children: ReactNode }) => {
	return (
		<StyledContainer>
			<div className="mr-1 bold">{`${translate({ key: 'Active filters' }).toUpperCase()} :`}</div>
			{children}
		</StyledContainer>
	);
};

export default ActiveFiltersBox;
