import { useCallback } from 'react';
import {
	namedOperations,
	StashItemType,
	useSaveYarnMutation,
	YarnDetailsFragment,
	YarnInput
} from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import RadioInput from 'components/Inputs/RadioInput';
import FormContainerLayout from 'components/Layouts/FormContainerLayout/FormContainerLayout';
import { FormProvider } from 'customHooks/formContexts/useYarnFormContext';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { ValueOf } from 'types/core';
import { useImmerReducer } from 'use-immer';
import YarnFormContentView from './YarnFormContentView/YarnFormContentView';
import { YarnFormReducer } from './YarnFormReducer';
import YarnFormSubmitView from './YarnFormSubmitView/YarnFormSubmitView';

interface YarnFormProps {
	yarn: YarnDetailsFragment;
	formTitle?: string,
	onSwitchItemType?: (itemType: StashItemType) => void
}

export const YarnForm = ({ yarn, formTitle, onSwitchItemType }: YarnFormProps) => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const [state, dispatch] = useImmerReducer(YarnFormReducer, yarn);
	const [saveYarn, { loading }] = useSaveYarnMutation({
		onError: err => {
			notifyError(err.message);
		},
		refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.getYarns, namedOperations.Query.getStashList],
		onCompleted: ({ saveYarn: yarn }) => {
			navigate(`/yarn/${yarn._id}`);
		}
	});

	const handleChange = useCallback(
		(key: keyof YarnInput, value: ValueOf<YarnInput>) => {
			dispatch({ key, value });
		}, [dispatch]
	);

	const onSubmit = useCallback(async () => {
		const {
			__typename,
			stashId,
			currentProjectsIds,
			gauge: { rows, sampleSize, stitches },
			needlesSize: { from, to },
			...yarn
		} = state;
		await saveYarn({
			variables: {
				input: {
					...yarn,
					gauge: {
						rows,
						stitches,
						sampleSize
					},
					needlesSize: {
						from,
						to
					}
				}
			},
			refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.getYarns],
		});
	}, [saveYarn, state]);

	return (
		<FormContainerLayout
			formTitle={formTitle}
			onSave={onSubmit}
			loading={loading}
			isValid={!!state.name}
			disabledMessage={translate({ key: 'You must give a name to the yarn' })}
		>
			<FormProvider value={{ state, handleChange }}>
				<FlexContainer>
					<RadioInput
						disabled={!onSwitchItemType}
						options={[
							{ label: translate({ key: 'Fabric' }), value: StashItemType.Fabric },
							{ label: translate({ key: 'Yarn' }), value: StashItemType.Yarn },
						]}
						onChange={(value: StashItemType) => onSwitchItemType(value)}
						value={StashItemType.Yarn}
						inputName={'Item type'}
					/>
				</FlexContainer>
				<YarnFormContentView/>
				<YarnFormSubmitView onSubmit={onSubmit} disabled={!state.name}/>
			</FormProvider>
		</FormContainerLayout>
	);
};

YarnForm.defaultProps = {
	formTitle: 'New item',
	yarn: {
		name: null,
		shop: null,
		quantity: null,
		colors: [],
		composition: [],
		gauge: {
			rows: null,
			stitches: null,
			sampleSize: null
		},
		needlesSize: {
			from: null,
			to: null
		},
		notes: null,
		weightCategories: []
	}
};

export default YarnForm;
