import { ReactNode } from 'react';
import { Divider as MuiDivider, DividerProps } from '@mui/material';

const Divider = ({
	vertical,
	label,
	className,
	...props
}: DividerProps & { label?: string | ReactNode, vertical?: boolean }) => {
	return (
		<MuiDivider orientation={vertical ? 'vertical' : 'horizontal'} variant="middle"
			className={`w-100 pv-1 ${className}`} {...props}>
			{label}
		</MuiDivider>
	);
};

Divider.defaultProps = {
	vertical: false
};
export default Divider;
