import { Suspense } from 'react';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import NotFoundPage from 'pages/NotFoundPage';
import { Route, Routes } from 'react-router-dom';
import routes from 'routes/demonstration';
import RouteComponent from 'routes/RouteComponent';
import { ThemeProvider } from 'styled-components';
import { theme } from 'themes/main';
import { DemonstrationProvider } from './DemonstrationContext';
import { DemonstrationPageHeader } from './DemonstrationPageHeader/DemonstrationPageHeader';
import { DemonstrationPageSidebar } from './DemonstrationPageSidebar/DemonstrationPageSidebar';
import { StyledContainer, StyledContent, StyledDemonstrationPage } from './DemonstrationPageStyles';

export const DemonstrationPage = () => {
	const { isMediumScreen } = useScreenWidth();

	return <ThemeProvider theme={theme}>
		<DemonstrationProvider>
			<StyledDemonstrationPage>
				{!isMediumScreen && <DemonstrationPageSidebar/>}
				<StyledContainer>
					<DemonstrationPageHeader/>
					<StyledContent>
						<Suspense fallback={(() => <div/>)()}>
							<Routes>
								{routes.map(({ path, Component }) => (
									<Route
										key={path}
										path={path}
										element={<RouteComponent Component={Component} needAuthentication={false}/>}
									/>
								))}
								<Route path="*" element={<NotFoundPage/>}/>
							</Routes>
						</Suspense>
					</StyledContent>
				</StyledContainer>
			</StyledDemonstrationPage>
		</DemonstrationProvider>
	</ThemeProvider>;
};
