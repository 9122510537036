import { useEffect, useState } from 'react';
import { namedOperations, useLoginWithProviderMutation } from '__generated__/graphql';
import useNotifications from 'customHooks/utils/useNotifications';
import { AuthCredential, GoogleAuthProvider, OAuthCredential, User } from 'firebase/auth';
import firebase from 'firebase/compat/app';
import { useLocation, useNavigate } from 'react-router-dom';
import 'firebase/compat/auth';

const firebaseApp = firebase.initializeApp({
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
}, process.env.REACT_APP_FIREBASE_APP_NAME);

const useFirebaseAuthentication = () => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const { state } = useLocation() as { state: { from: { pathname: string, search: string } } };
	const [email, setEmail] = useState<string | null>(null);
	const [token, setToken] = useState<string | null>(null);
	const [loginWithProvider, { loading }] = useLoginWithProviderMutation({
		onError: err => {
			notifyError(err.message);
		},
		onCompleted: () => {
			navigate(state?.from ? `${state.from.pathname}${state.from.search}` : '/', { replace: true });
		},
		refetchQueries: [namedOperations.Query.getAuthenticatedUser]
	});

	useEffect(() => {
		if (email && token) {
			loginWithProvider({ variables: { email, token } });
		}
	}, [email, token, loginWithProvider]);

	const uiConfig = {
		signInFlow: 'popup',
		signInOptions: [
			{
				provider: GoogleAuthProvider.PROVIDER_ID,
				customParameters: {
					prompt: 'select_account'
				}
			}
		],
		callbacks: {
			signInSuccessWithAuthResult: (authResult: { user: User, credential: AuthCredential }) => {
				const credential = authResult.credential.toJSON() as OAuthCredential;
				setToken(credential?.idToken || null);
				setEmail(authResult?.user?.email);
				return false;
			}
		}
	};
	return {
		auth: firebaseApp.auth(), uiConfig, signOutOfGoogle: () => {
			console.log('signed out');
		}, loading
	};
};

export default useFirebaseAuthentication;
