import { useCallback } from 'react';
import { namedOperations, ProjectDetailsFragment, useSaveProjectMutation } from '__generated__/graphql';
import FormContainerLayout from 'components/Layouts/FormContainerLayout/FormContainerLayout';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import ProjectImage from 'components/ProjectImage/ProjectImage';
import { ExtendedProjectInput, FormProvider } from 'customHooks/formContexts/useProjectFormContext';
import useNotifications from 'customHooks/utils/useNotifications';
import { useNavigate } from 'react-router-dom';
import { ValueOf } from 'types/core';
import { useImmerReducer } from 'use-immer';
import { ProjectFormReducer } from 'views/projects/ProjectForm/ProjectFormReducer';
import ProjectFormContentView from './ProjectFormContentView/ProjectFormContentView';
import { translate } from '../../../services/i18n';

const ProjectForm = ({
	formTitle,
	project
}: { formTitle: string, project: ProjectDetailsFragment }) => {
	const { notifyError } = useNotifications();
	const navigate = useNavigate();
	const [state, dispatch] = useImmerReducer(ProjectFormReducer, project);
	const [saveProject, { loading }] = useSaveProjectMutation({
		onError: err => {
			notifyError(err.message);
		},
		refetchQueries: [namedOperations.Query.getUserFilters, namedOperations.Query.getProjectsList],
		onCompleted: ({ saveProject: project }) => {
			navigate(`/projects/${project._id}`);
		}
	});

	const handleChange = useCallback(
		(key: keyof ExtendedProjectInput, value: ValueOf<ExtendedProjectInput>) => {
			dispatch({ key, value });
		}, [dispatch]
	);

	const onSubmit = useCallback(async () => {
		const { __typename, patterns, inspirations, stash, ...input } = state;
		await saveProject({
			variables: { input },
		});
	}, [saveProject, state]);
	return (
		<FormContainerLayout
			formTitle={formTitle}
			loading={loading}
			onSave={onSubmit}
			isValid={!!state.name}
			disabledMessage={translate({ key: 'You must give a name to the project' })}
		>
			<FormProvider value={{ state, handleChange }}>
				<FormLayout
					imgContent={<ProjectImage project={state}/>}
					mainContent={<ProjectFormContentView/>}/>
			</FormProvider>
		</FormContainerLayout>
	);
};
ProjectForm.defaultProps = {
	project: {
		name: null,
		patternIds: [],
		stashIds: [],
		inspirationIds: [],
		inspirations: [],
		patterns: [],
		stash: [],
		finishedAt: null,
		notes: null,
		recipient: null,
		startedAt: null,
		status: null,
		tags: [],
	}
};
export default ProjectForm;
