import styled from 'styled-components';
import { Div } from './Div';

interface IFlexContainer {
	justifyContent?: string;
	alignitems?: string;
	aligncontent?: string;
	column?: boolean;
}

export const FlexContainer = styled(Div) <IFlexContainer>`
  display: flex;
  flex-direction: ${({ column }) => column ? 'column' : 'row'};
  justify-content: ${({ justifyContent }) => justifyContent};
  align-items: ${({ alignitems }) => alignitems};
  align-content: ${({ aligncontent }) => aligncontent};
  width: 100%;

  h1 {
    width: 100%;
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightgrey};

    .button {
      font-size: 14px !important;
    }
  }
`;
FlexContainer.defaultProps = {
	justifyContent: 'center',
	alignitems: 'center',
	aligncontent: 'center',
	column: false
};
