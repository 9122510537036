import { useCallback } from 'react';
import { Link } from '__generated__/graphql';
import ExpendableContainer from 'components/base/ExpendableContainer';
import { FlexContainer } from 'components/base/FlexContainer';
import { Label } from 'components/base/Label';
import FileInput from 'components/Inputs/FileInput';
import LinkInput from 'components/Inputs/LinkInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import { translate } from 'services/i18n';
import { updateObjectArray } from 'services/utils';
import { StyledPlusIcon } from './PatternFormRelevantLinksStyles';
import Divider from '../../../../components/base/Divider';

const PatternFormLinksView = () => {
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;

	const updateVideos = useCallback((value: Link | null, index?: number) => {
		const updatedVideos = updateObjectArray(state.relevantVideos, value, index) as Link[];
		handleChange('relevantVideos', updatedVideos);
	}, [state.relevantVideos, handleChange]);

	const updateFiles = useCallback((value: Link | null, index?: number) => {
		const updatedFiles = updateObjectArray(state.files, {
			name: value.name,
			file: { url: value.url }
		}, index) as Link[];
		handleChange('files', updatedFiles);
	}, [state.files, handleChange]);

	return <ExpendableContainer label={translate({ key: 'Files and links' })}>
		<GridContainer className="pt-1">
			<GridItem md={12}>
				<FlexContainer justifyContent="space-between">
					<Label>{translate({ key: 'Videos' })}</Label>
					<StyledPlusIcon className="fa fa-solid fa-plus" onClick={() => {
						updateVideos({ name: '', url: '', description: '' } as Link);
					}}/>
				</FlexContainer>
				{(state.relevantVideos).map(({ name, url }, index) => {
					return (
						<LinkInput
							key={index}
							onChange={(video) => updateVideos({
								...video,
								description: ''
							})}
							url={url} name={name}/>
					);
				})}
			</GridItem>
			<Divider/>
			<GridItem md={12}>
				<FlexContainer justifyContent="space-between">
					<Label>{translate({ key: 'Files' })}</Label>
					<StyledPlusIcon className="fa fa-solid fa-plus" onClick={() => {
						updateFiles({ name: '', url: '', description: '' } as Link);
					}}/>
				</FlexContainer>
				{(state.files || []).map(({ name, file: { url } }, index) => {
					return <FileInput
						key={index}
						url={url}
						name={name}
						onChange={file => updateFiles({
							...file,
							description: ''
						} as Link, index)}/>;

				})}
			</GridItem>
		</GridContainer>
	</ExpendableContainer>;
};

export default PatternFormLinksView;
