import { FlexContainer } from 'components/base/FlexContainer';
import styled from 'styled-components';

export const StyledHeader = styled(FlexContainer)`
    height: 3em;
    background: ${({ theme }) => theme.colors.white};
    padding: 0.5em 2em;
    justify-content: flex-end;
    position: fixed;
    z-index: 200;
    box-shadow: 0px 2px 4px 0px ${({ theme }) => theme.colors.darkgrey};
  width: calc(100% - 24em);
  a {
    font-weight: bold;
    text-decoration: underline;
    svg:hover {
      path {
        fill: ${({ theme }) => theme.colors.green}
      }
    }
  }
    @media screen and (max-width: 1200px) {  
      width: calc(100% - 2em);
      padding: 0.5em 1em;
    }
    svg.fa-bars,svg.fa-xmark, svg.fa-power-off, svg.fa-user, svg.fa-user-plus {
      height: 2em;
      @media screen and (max-width: 1200px) {
        height: 1.5em;
      }
    }
  svg.fa-user,svg.fa-user-plus, svg.fa-power-off {
    @media screen and (max-width: 1200px) {
      margin-left: 1em;
    }
  }
    svg.fa-house {
      margin-right: 1em;
    }

    i {
        font-size: 2em;
        &:hover {
            color: ${({ theme }) => theme.colors.green};
        }
    }
    .logout:hover {
        color: ${({ theme }) => theme.colors.green};
        cursor: pointer;
    }
`;

export const StyledLogoContainer = styled(FlexContainer)`
  font-size: 2em!important;
  text-shadow: 1px 1px 2px lightcyan;
  line-height: 1em;
  font-weight: bold;
  font-family: Parisienne;
  @media screen and (max-width: 768px) {
    font-size: 1.4em!important;
  }
  span { 
    text-align: left;
  }
`;

export const StyledMobileMenu = styled.div<{visible: boolean}>`
  position: fixed;
  top: 4em;
  left:${({ visible }) => visible ? 0 : 'calc(-100% - 6em)'};
  transition: left 0.5s;
  a {
    text-decoration: none;
  }
`;
