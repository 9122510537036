import { lazy } from 'react';

const AdminBrandsListView = lazy(() => import('views/admin/brands/AdminBrandsListView'));
const UsersListView = lazy(() => import('views/admin/users/UsersListView'));
const MessagesListView = lazy(() => import('views/admin/messages/MessagesListView'));

export default [
	{ path: 'brands', name: 'Brands', Component: AdminBrandsListView, needAuthentication: true },
	{ path: 'users', name: 'Users', Component: UsersListView, needAuthentication: true },
	{ path: 'messages', name: 'Messages', Component: MessagesListView, needAuthentication: true }
];
