import { useEffect, useState } from 'react';
import useVideoUrlParser from 'customHooks/utils/useVideoUrlParser';
import styled from 'styled-components';

interface RemoteVideoProps {
	videoUrl: string;
	embeddedHTML?: string;
	disableClick?: boolean;
}

const IFrame = styled.iframe`
  border-radius: 0.5em;
  width: 100%;
  height: 100%;
  // aspect-ratio: 16 / 9;
  border-width: 0 !important;
`;
const RemoteVideo = ({ videoUrl, embeddedHTML, disableClick }: RemoteVideoProps) => {
	const { parseVideoUrl } = useVideoUrlParser();
	const [loaded, setLoaded] = useState(false);
	const [sourceUrl, setSourceUrl] = useState(videoUrl);
	const [displayedHTML, setDisplayedHTML] = useState(embeddedHTML);
	const [provider, setProvider] = useState(null);
	useEffect(() => {
		parseVideoUrl({ videoUrl }).then(data => {
			setSourceUrl(data.videoUrl);
			setDisplayedHTML(data.embeddedHTML);
			setProvider(data.provider);
			setLoaded(true);
		});
	}, [videoUrl, parseVideoUrl]);
	if (!loaded) {
		return <></>;
	}
	return displayedHTML ? <div dangerouslySetInnerHTML={{ __html: displayedHTML }}/> :
		<IFrame
			src={sourceUrl}
			style={{
				...provider === 'Youtube' ? { aspectRatio: '16 / 9' } : {},
				...disableClick ? { pointerEvents: 'none' } : {}
			}}
		/>;
};

export default RemoteVideo;
