import { FormLabel } from '@mui/material';
import { QuantityUnit } from '__generated__/graphql';
import { FlexContainer } from 'components/base/FlexContainer';
import { translate } from 'services/i18n';
import NumberInput from './NumberInput';
import Select from './Select';

const units = [
	QuantityUnit.Yard,
	QuantityUnit.Meters,
	QuantityUnit.Grams
];

interface QuantityInputProps {
	onChange: (value: { unit: QuantityUnit, quantity: number }) => void;
	quantity: number;
	unit: QuantityUnit;
	label: string;
}

const QuantityInput = ({ onChange, quantity, unit, label }: QuantityInputProps) => {
	return (<>
		<FormLabel>{translate({ key: label })}</FormLabel>
		<FlexContainer>
			<NumberInput
				sx={{ marginRight: '1em', maxWidth: '50%' }}
				onNumberChange={updatedQuantity => onChange({ quantity: updatedQuantity, unit })}
				value={quantity}
				placeholder={translate({ key: 'Quantity' })}
			/>
			<Select
				className="w-50"
				value={{ value: unit, label: translate({ key: unit }) }}
				onChange={(updatedUnit: string) => onChange({ quantity, unit: updatedUnit as unknown as QuantityUnit })}
				clearable={false}
				searchable={false}
				data={units.map(unit => ({ value: unit, label: translate({ key: unit }) }))}
			/>
		</FlexContainer>
	</>);
};

export default QuantityInput;
