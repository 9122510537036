import { InspirationDetailsFragment } from '__generated__/graphql';
import FieldDescriptionBox from 'components/base/FieldDescriptionBox';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import { translate } from 'services/i18n';

const InspirationDetailsContentView = ({ inspiration }: { inspiration: InspirationDetailsFragment }) => {
	return (
		<GridContainer>
			<FieldDescriptionBox
				label={translate({ key: 'Categories' })}
				content={inspiration.categories?.length ? inspiration.categories.join(' - ') : '--'}
			/>
			<FieldDescriptionBox
				label={translate({ key: 'Tags' })}
				content={inspiration.tags?.length ? inspiration.tags.join(' - ') : '--'}
			/>
		</GridContainer>
	);
};

export default InspirationDetailsContentView;
