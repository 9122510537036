import HostedImageInput from 'components/Inputs/ImageInput/HostedImageInput';
import FormLayout from 'components/Layouts/FormLayout/FormLayout';
import { PatternFormContextProps, usePatternFormContext } from 'customHooks/formContexts/usePatternFormContext';
import { translate } from 'services/i18n';
import PatternFormAdditionalInfosView from './PatternFormAdditionalInfosView/PatternFormAdditionalInfosView';
import PatternFormLinksView from './PatternFormLinksView/PatternFormLinksView';
import PatternFormMainContentView from './PatternFormMainContentView/PatternFormMainContentView';
import GridContainer from '../../../components/Layouts/Grid/GridContainer';
import GridItem from '../../../components/Layouts/Grid/GridItem';

const PatternFormContent = () => {
	const FormContext = usePatternFormContext();
	const { state, handleChange }: PatternFormContextProps = FormContext;
	return (
		<FormLayout
			imgContent={<HostedImageInput
				id="pattern-image"
				image={state.hostedImage || null}
				remoteUrl={state.imageUrl}
				onChange={image => {
					handleChange('hostedImage', image);
				}}
				onRemoteUrlChange={(url) => {
					handleChange('imageUrl', url);
				}}
				name={state._id || 'image'}
				alt={translate({ key: 'PatternFilters picture' })}/>}
			mainContent={<PatternFormMainContentView/>}>
			<GridContainer spacing={8}>
				<GridItem>
					<PatternFormAdditionalInfosView/>
				</GridItem>
				<GridItem>
					<PatternFormLinksView/>
				</GridItem>
			</GridContainer>
		</FormLayout>
	);
};

export default PatternFormContent;
