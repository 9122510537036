import MultiSelectInput from 'components/Inputs/MultiSelect';
import TextInput from 'components/Inputs/TextInput';
import GridContainer from 'components/Layouts/Grid/GridContainer';
import GridItem from 'components/Layouts/Grid/GridItem';
import useUserFilters from 'customHooks/data/useUserFilters';
import useInspirationsSearchEngine from 'customHooks/searchEngine/useInspirationsSearchEngine';
import { translate } from 'services/i18n';

const InspirationsListFiltersView = () => {
	const { currentFilters, updateUrl } = useInspirationsSearchEngine({ limit: 12 });
	const { tags, categories } = useUserFilters();

	return (
		<GridContainer className="pb-1 mb-1">
			<GridItem md={4}>
				<TextInput
					label={translate({ key: 'Name' })}
					value={currentFilters.name}
					onChange={name => updateUrl('name', name)}
					id="inspirations-name-filter"/>
			</GridItem>
			<GridItem md={4}>
				<MultiSelectInput
					label={translate({ key: 'Tags' })}
					data={tags.map(value => ({ value, label: value }))}
					value={currentFilters.tags.map(value => ({ value, label: value }))}
					onChange={updatedTags => updateUrl('tags', updatedTags.join(','))}/>
			</GridItem>
			<GridItem md={4}>
				<MultiSelectInput
					label={translate({ key: 'Categories' })}
					data={categories.map(value => ({ value, label: value }))}
					value={currentFilters.categories.map(value => ({ value, label: value }))}
					onChange={updatedCategories => updateUrl('categories', updatedCategories.join(','))}/>
			</GridItem>
		</GridContainer>
	);
};

export default InspirationsListFiltersView;
