import Divider from 'components/base/Divider';
import { FlexContainer } from 'components/base/FlexContainer';
import usePatternsSearchEngine from 'customHooks/searchEngine/usePatternsSearchEngine';
import { translate } from 'services/i18n';
import PatternsListFiltersView from './PatternsListFiltersView/PatternsListFiltersView';
import PatternsListResultsView from './PatternsListResultsView/PatternsListResultsView';

const PatternsListView = () => {
	const { data } = usePatternsSearchEngine();
	return <FlexContainer column>
		<PatternsListFiltersView/>
		<Divider
			sx={{ marginTop: '2em' }}
			label={<strong>
				{translate({ key: 'Search results', data: { count: data?.count || 0 } })}
			</strong>}/>
		<PatternsListResultsView/>
	</FlexContainer>;
};

export default PatternsListView;

