import { useEffect, useState } from 'react';
import { namedOperations, useLoginWithPasswordMutation } from '__generated__/graphql';
import Divider from 'components/base/Divider';
import { Error } from 'components/base/Error';
import { FlexContainer } from 'components/base/FlexContainer';
import LoadingOverlay from 'components/base/LoadingOverlay';
import { Button } from 'components/Button/Button';
import Checkbox from 'components/Inputs/Checkbox';
import PasswordInput from 'components/Inputs/PasswordInput';
import TextInput from 'components/Inputs/TextInput';
import { useAuthenticatedUser } from 'customHooks/data/useAuthenticatedUser';
import useScreenWidth from 'customHooks/utils/useScreenWidth';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { translate } from 'services/i18n';
import { isValidEmail } from 'services/utils';
import FirebaseSigninView from './FirebaseSigninView/FirebaseSigninView';

const LoginView = () => {
	const navigate = useNavigate();
	const { state } = useLocation() as { state: { from: { pathname: string, search: string } } };
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rememberMe, setRememberMe] = useState(false);
	const { user } = useAuthenticatedUser();
	const [error, setError] = useState<null | string>(null);

	const [loginWithPassword, { loading }] = useLoginWithPasswordMutation({
		fetchPolicy: 'network-only',
		onError: (err) => {
			setError(err.message);
		},
		onCompleted: async () => {
			navigate(state?.from ? `${state.from.pathname}${state.from.search}` : '/', { replace: true });
		},
		refetchQueries: [namedOperations.Query.getProfile, namedOperations.Query.getAuthenticatedUser]
	});
	const { isSuperSmallScreen } = useScreenWidth();

	useEffect(() => {
		if (user) {
			navigate(state?.from ? `${state.from.pathname}${state.from.search}` : '/', { replace: true });
		}
	}, [user, navigate, state]);

	useEffect(() => {
		const handlePressEnter = (e: KeyboardEvent) => {
			if (e.key === 'Enter' && email.length && password.length) {
				loginWithPassword({ variables: { email, password } });
			}
		};
		window.addEventListener('keypress', handlePressEnter);
		return () => window.removeEventListener('keypress', handlePressEnter);
	}, [loginWithPassword, email, password]);

	return !user ? <FlexContainer column>
		{loading && <LoadingOverlay/>}
		<FlexContainer className="w-1-3 w-md-75 w-sm-100" column>
			<TextInput
				id={'email-login'}
				label={translate({ key: 'Email' })}
				value={email}
				onChange={(typedEmail) => {
					setError(null);
					setEmail(typedEmail);
				}}
				disabled={loading}
				validate={(email: string) => !email.length || isValidEmail(email)}
				errorMessage={translate({ key: 'Invalid email' })}
			/>
			<PasswordInput
				label={translate({ key: 'Password' })}
				value={password}
				onChange={(password: string) => {
					setError(null);
					setPassword(password);
				}}
				disabled={loading}/>
			<Checkbox
				id="login-remember-me"
				label={translate({ key: 'Remember me' })}
				checked={rememberMe}
				onChange={() => setRememberMe(!rememberMe)}/>
			{error && <Error>{translate({ key: error })}</Error>}
			<Button
				className="mt w-50 w-sm-75"
				disabled={!email.length || (email && !isValidEmail(email)) || !password.length || loading}
				label={translate({ key: 'Connect' })}
				onClick={() => loginWithPassword({ variables: { email, password } })}
			/>
			<Link to={'/forgetPassword'}>
				<div className="pt-1">{`${translate({ key: 'Forgot your password' })}?`}</div>
			</Link>
		</FlexContainer>
		<div className="w-50 w-md-75 w-sm-100 pt-1"><Divider label={translate({ key: 'OR' })}/></div>
		{!user && <FirebaseSigninView/>}
		{isSuperSmallScreen && (
			<div className="w-100">
				<div className={'w-100 pb-1'}><Divider label={translate({ key: 'OR' })}/></div>
				<Button
					label={translate({ key: 'Create an account' })}
					onClick={() => navigate('/signin')}
				/>
			</div>
		)}
	</FlexContainer> : <></>;
};

export default LoginView;
