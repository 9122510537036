import styled from 'styled-components';

export const StyledMainPageSidebar = styled.div`
  background: ${({ theme }) => theme.colors.darkblue};
  padding: 1em;
  width: 18em;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 2em);
  position: fixed;
  @media screen and (max-width: 1200px) {
    padding: 2em 1em 1em 1em;
    height: calc(100% - 6em);
    width: 16em;
  }
  @media screen and (max-width: 767px) {
    height: calc(100% - 6em);
    width: calc(100% - 2em);
  }
`;

export const StyledLogoContainer = styled.div`
  margin-bottom: 2em;
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 2em !important;
  text-shadow: 1px 1px 2px lightcyan;
  font-weight: bold;
  font-family: Parisienne;

  a {
    line-height: 1em;
    height: 2em;
    color: white;

    &:hover {
      color: lightcyan;
    }
  }
`;

interface StyledMenuItemProps {
	sub?: boolean;
	active?: boolean;
}

export const StyledMainPageSidebarMenuItem = styled.div<StyledMenuItemProps>`
  text-align: left !important;

  a {
    text-decoration: none;
    color: ${({ theme, active }) => active ? theme.colors.activeLink : theme.colors.white};

    &:hover {
      color: ${({ theme }) => theme.colors.brightgreen};
    }
  }

  padding-left: ${({ sub }) => sub ? '1.5em' : 0};
  padding-bottom: 1em;
  font-weight: ${({ active }) => active ? 'bold' : 'normal'};
  font-style: ${({ active }) => active ? 'italic' : 'normal'};
`;

export const StyledHeader = styled.div`
  text-transform: uppercase;
  margin-bottom: 1em;
  margin-right: 2em;
  font-weight: bold;
  border-bottom: 1px solid;
`;
