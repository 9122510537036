import { Breadcrumbs, Link, Stack, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { RouteProps } from 'routes/main';
import { translate } from 'services/i18n';

const MainPageBreadcrumbs = ({ route }: { route: RouteProps }) => {
	const navigate = useNavigate();
	if (route.path === '/') return;
	const breadcrumbs = [
		<Link
			sx={{
				'&:hover': {
					cursor: 'pointer'
				}
			}}
			underline="hover"
			key={0}
			color="inherit"
			onClick={e => {
				e.preventDefault();
				e.stopPropagation();
				navigate('/');
			}}>
			{translate({ key: 'Home' })}
		</Link>
	].concat((route.breadcrumbs || []).map((breadcrumb, index) => <Link
		sx={{
			'&:hover': {
				cursor: 'pointer'
			}
		}}
		underline="hover"
		key={index + 1}
		color="inherit"
		onClick={e => {
			e.preventDefault();
			e.stopPropagation();
			navigate(breadcrumb.link);
		}}>
		{translate({ key: breadcrumb.name })}
	</Link>)).concat([
		<Typography key={(route.breadcrumbs || []).length + 1} color="main" variant="h6" sx={{ fontWeight: 'bold' }}>
			{translate({ key: route.name })}
		</Typography>
	]);
	return (
		<Stack spacing={2}>
			<Breadcrumbs separator="›" aria-label="breadcrumb">
				{breadcrumbs}
			</Breadcrumbs>
		</Stack>
	);
};

export default MainPageBreadcrumbs;
